import {
	ComparisonType,
	CustomersFiltersWithMetadata,
	CustomersTableHeader,
	CustomerTable
} from '@/types/customer';

export const customersHeader: CustomersTableHeader[] = [
	{ key: 'id', title: 'Id' },
	{ key: 'edit', title: 'Edit' },
	{ key: 'status', title: 'Status' },
	{ key: 'name_first', title: 'First Name' },
	{ key: 'name_last', title: 'Last Name' },
	{ key: 'customer_type', title: 'Type' },
	{ key: 'company_name', title: 'Company Name' },
	{ key: 'phone', title: 'Primary Phone' },
	{ key: 'secondary_phone', title: 'Secondary Phone' },
	{ key: 'email', title: 'Email' },
	{ key: 'full_address', title: 'Full Address' },
	{ key: 'notes', title: 'Notes' },
	{ key: 'fax', title: 'Fax' },
	{ key: 'tax_exempt', title: 'Tax Exempt?' },
	{ key: 'po_required', title: 'PO Required?' }
];

export type NormalizedCustomer = Pick<
	CustomerTable,
	| 'id'
	| 'status'
	| 'flagged'
	| 'customer_type'
	| 'name_first'
	| 'name_last'
	| 'company_name'
	| 'phone'
	| 'secondary_phone'
	| 'full_address'
	| 'fax'
	| 'tax_exempt'
	| 'po_required'
	| 'notes'
	| 'email'
>;

export const customersFilters: CustomersFiltersWithMetadata<NormalizedCustomer> =
	{
		id: { val: null, type: ComparisonType.like },
		status: { val: null, type: ComparisonType.exact },
		flagged: { val: null, type: ComparisonType.exact },
		customer_type: { val: null, type: ComparisonType.exact },
		name_first: { val: null, type: ComparisonType.like },
		name_last: { val: null, type: ComparisonType.like },
		company_name: { val: null, type: ComparisonType.like },
		phone: { val: null, type: ComparisonType.like },
		secondary_phone: { val: null, type: ComparisonType.like },
		full_address: { val: null, type: ComparisonType.like },
		fax: { val: null, type: ComparisonType.like },
		tax_exempt: { val: null, type: ComparisonType.exact },
		po_required: { val: null, type: ComparisonType.exact },
		notes: { val: null, type: ComparisonType.like },
		email: { val: null, type: ComparisonType.like }
	};

export const TABLE_ID = 'customersTable';
export const ITEMS_PER_REQUEST = 50;

export const flaggedOptions = [
	{ id: 'yes', name: 'Flagged' },
	{ id: '0', name: 'Not Flagged' }
];

export const exemptOptions = [
	{ id: 'yes', name: 'Exempt' },
	{ id: '0', name: 'Not Exempt' }
];

export const requiredOptions = [
	{ id: 'yes', name: 'Required' },
	{ id: '0', name: 'Not Required' }
];
