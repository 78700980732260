<script setup lang="ts">
import { isEqual } from 'lodash-es';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import { useConfirm } from 'primevue/useconfirm';
import { defineModel, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import CustomerSidebar from '@/components/core/editCustomer/CustomerSidebar.vue';
import ContactInfo from '@/components/core/editCustomer/tabs/contactInfo/ContactInfo.vue';
import CustomerPreviousJobs from '@/components/core/editCustomer/tabs/previousJobs/CustomerPreviousJobs.vue';
import CustomerSettings from '@/components/core/editCustomer/tabs/settings/CustomerSettings.vue';
import CustomerShopSettings from '@/components/core/editCustomer/tabs/shopSettings/CustomerShopSettings.vue';
import CustomerVehicles from '@/components/core/editCustomer/tabs/vehicles/CustomerVehicles.vue';
import useCustomerEditStore from '@/store/customerEdit/customerEditStore';

const tabs = {
	contactInfo: 'Contacts',
	previousJobs: 'Transactions',
	settings: 'Settings',
	shopSettings: 'Pricing',
	vehicles: 'Vehicles'
};

const confirm = useConfirm();
const store = useCustomerEditStore();
const { t } = useI18n();

const activeTab = ref(tabs.contactInfo);
const showModal = defineModel<boolean>('show');

const close = () => {
	if (
		(store.customerStateBeforeChanges &&
			!isEqual(store.customerStateBeforeChanges, store.customer.state)) ||
		(!store.customerStateBeforeChanges && !store.customer.state.id)
	) {
		confirm.require({
			accept: () => {
				store.onClose();
				showModal.value = false;
			},
			acceptLabel: t('yes'),
			header: 'Confirmation',
			message: 'Close modal with unsaved changes?',
			rejectLabel: t('no'),
			rejectProps: {
				severity: 'secondary'
			}
		});
	} else {
		store.onClose();
		showModal.value = false;
	}
};

watch(
	() => showModal.value,
	() => {
		activeTab.value = tabs.contactInfo;
	}
);
</script>

<template>
	<Dialog
		id="vue3EditCustomerDialog"
		appendTo="#vue3app"
		class="tw3-w-full tw3-max-w-[1000px] tw3-h-[860px]"
		contentClass="tw3-flex tw3-flex-1 tw3-border-t tw3-border-gray-200"
		contentStyle="padding: 0; flex-direction: row"
		modal
		pt:mask:class="custom-dialog-z-index"
		:visible="showModal"
		@update:visible="close"
	>
		<template #header>
			<div class="tw3-flex tw3-items-center tw3-gap-4">
				<span class="tw3-text-xl tw3-font-bold">
					{{
						store.customer.state.id
							? store.customer.state.is_insurance
								? 'Insurance Profile'
								: `${t('customer')} Profile`
							: store.customer.state.is_insurance
								? 'New Insurance'
								: `New ${t('customer')}`
					}}
				</span>
				<ButtonGroup
					v-if="store.customer.state.is_insurance && !store.customer.state.id"
				>
					<Button label="LYNX" severity="secondary" @click="store.setLynx" />
					<Button label="SGC" severity="secondary" @click="store.setSgc" />
					<Button label="NONE" severity="secondary" @click="store.setNone" />
				</ButtonGroup>
			</div>
		</template>
		<div
			v-if="store.customer.isLoading"
			class="tw3-flex tw3-flex-1 tw3-justify-center tw3-items-center"
		>
			<ProgressSpinner
				aria-label="Loading"
				class="tw3-fill-sky-500"
				fill="transparent"
				style="width: 50px; height: 50px"
			/>
		</div>
		<template v-else>
			<CustomerSidebar class="tw3-w-72" />
			<div
				class="tw3-flex tw3-flex-col tw3-flex-1 tw3-border-l tw3-border-gray-200"
			>
				<Tabs v-model:value="activeTab" class="tw3-overflow-y-auto">
					<TabList pt:tabs:style="background-color: transparent">
						<Tab :value="tabs.contactInfo">{{ tabs.contactInfo }}</Tab>
						<Tab :value="tabs.settings">{{ tabs.settings }}</Tab>
						<Tab :value="tabs.shopSettings">
							{{ tabs.shopSettings }}
						</Tab>
						<Tab
							v-if="!store.customer.state.is_insurance"
							:value="tabs.vehicles"
						>
							{{ tabs.vehicles }}</Tab
						>
						<Tab
							v-if="!store.customer.state.is_insurance"
							:value="tabs.previousJobs"
						>
							{{ tabs.previousJobs }}</Tab
						>
					</TabList>
					<TabPanels
						class="tw3-overflow-y-auto"
						style="box-shadow: inset 0 7px 10px -10px rgba(0, 0, 0, 0.3)"
					>
						<TabPanel :value="tabs.contactInfo">
							<ContactInfo v-if="activeTab === tabs.contactInfo" />
						</TabPanel>
						<TabPanel :value="tabs.settings">
							<CustomerSettings v-if="activeTab === tabs.settings" />
						</TabPanel>
						<TabPanel :value="tabs.shopSettings">
							<CustomerShopSettings v-if="activeTab === tabs.shopSettings" />
						</TabPanel>
						<TabPanel
							v-if="!store.customer.state.is_insurance"
							:value="tabs.vehicles"
						>
							<CustomerVehicles v-if="activeTab === tabs.vehicles" />
						</TabPanel>
						<TabPanel
							v-if="!store.customer.state.is_insurance"
							:value="tabs.previousJobs"
						>
							<CustomerPreviousJobs v-if="activeTab === tabs.previousJobs" />
						</TabPanel>
					</TabPanels>
				</Tabs>
				<div
					class="tw3-mt-auto tw3-border-t tw3-border-gray-200 tw3-flex tw3-items-center tw3-justify-end tw3-gap-4 tw3-p-4"
				>
					<Button
						label="Cancel"
						severity="secondary"
						size="small"
						type="button"
						@click="close"
					/>
					<Button
						label="Save"
						:loading="store.hydrateLoading"
						severity="info"
						size="small"
						type="button"
						@click="store.hydrateCustomer"
					/>
				</div>
			</div>
		</template>
	</Dialog>
</template>

<style>
.custom-dialog-z-index {
	z-index: 1999 !important;
}

.beautiful-scroll::-webkit-scrollbar {
	width: 8px;
}

.beautiful-scroll::-webkit-scrollbar-track {
	background: white;
	border-radius: 8px;
}

.beautiful-scroll::-webkit-scrollbar-thumb {
	background: #b6b6b6;
	border-radius: 8px;
}

.beautiful-scroll::-webkit-scrollbar-thumb:hover {
	background: #7c7c7c;
}
</style>
