import CORE_API from '../core';

import { normazileInsuranceClaims } from './utils';

import type {
	ArchiveResponse,
	Claim,
	ClaimDetailsRequest,
	ClaimDetailsResponse,
	ClaimRequestParams,
	ClaimResponse,
	CreateClaimRequest,
	CreateClaimResponse,
	NormalizedClaim
} from './types';

export const CLAIM_URL = '/unum/insurance/claim';

export const getClaim = async (params: ClaimDetailsRequest) => {
	try {
		const response = await CORE_API.get<ClaimDetailsResponse>(
			`${CLAIM_URL}/${params.id}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const createClaim = async (params: CreateClaimRequest) => {
	try {
		const response = await CORE_API.post<CreateClaimResponse>(
			`${CLAIM_URL}`,
			params
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getClaims = async (
	params: ClaimRequestParams
): Promise<ClaimResponse<NormalizedClaim>> => {
	const newParams = {
		...params,
		archived: params.archived === 'all' ? undefined : params.archived
	};
	try {
		const response = await CORE_API.get<ClaimResponse<Claim>>(CLAIM_URL, {
			params: newParams
		});

		return {
			claim: normazileInsuranceClaims(response.data.claim),
			totalCount: response.data.totalCount
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const patchClaim = async (
	id: number,
	archived: boolean
): Promise<void> => {
	try {
		await CORE_API.patch<ArchiveResponse>(`${CLAIM_URL}/${id}`, {
			archived,
			id: Number(id)
		});
	} catch (error) {
		console.error(error);
	}
};

export const archiveWorkAssignment = async (id: number): Promise<void> => {
	try {
		await CORE_API.get(`/api/glaxis-work-assignments/${id}/archive`);
	} catch (error) {
		console.error(error);
	}
};

export const getWorkAssignmentDetailsURL = (id: number): string =>
	`/api/glaxis-work-assignments/${id}`;
