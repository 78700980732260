<script setup lang="ts">
import { useClipboard } from '@vueuse/core';
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import Image from 'primevue/image';
import InputNumber from 'primevue/inputnumber';
import Popover from 'primevue/popover';
import Select from 'primevue/select';
import Tag from 'primevue/tag';
import { defineModel, defineProps, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { vehicleOwnerships, vehicleTypes } from './constants';
import noImg from './noImg.svg';

const { t } = useI18n();

const vehicle = defineModel<{
	id: number;
	imageUrl: string;
	make: string;
	makeId: string;
	model: string;
	nagsId: string;
	number: number;
	ownership: string;
	plateNumber: string;
	thumbUrl: string;
	type: string;
	vin: string;
	year: number;
	style: string;
}>('vehicle', { required: true });

defineProps<{
	status: boolean;
}>();

const { copy } = useClipboard();

const popoverRef = ref();
const imgUrl = ref(
	`https://storage.googleapis.com/nags-part-images/${vehicle.value.nagsId}.gif`
);

const toggleAdditionalInfoSettings = event => {
	popoverRef.value.toggle(event);
};

const handleError = () => {
	imgUrl.value = noImg;
};
</script>

<template>
	<div>
		<Fieldset class="tw3-relative tw3-h-[100%]" :legend="`${t('vehicle')}`">
			<div
				class="tw3-h-10 tw3-w-10 tw3-flex tw3-absolute tw3--top-7 tw3-right-6 tw3-bg-surface-50 tw3-border-surface-200 tw3-rounded-md tw3-border"
			>
				<Button
					aria-label="Settings"
					class="tw3-border-surface-200"
					icon="pi pi-cog"
					outlined
					severity="secondary"
					text
					type="button"
					@click="toggleAdditionalInfoSettings"
				/>

				<Popover ref="popoverRef" class="tw3-w-64">
					<div class="tw3-flex tw3-flex-col tw3-gap-2 tw3-w-full">
						<div>
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								{{ t('vehicleOwnership') }}
							</div>
							<Select
								v-model="vehicle.ownership"
								class="tw3-w-full"
								:disabled="status"
								optionLabel="label"
								:options="vehicleOwnerships"
								optionValue="value"
							/>
						</div>

						<div>
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								{{ t('vehicleType') }}
							</div>
							<Select
								v-model="vehicle.type"
								class="tw3-w-full"
								:disabled="status"
								optionLabel="label"
								:options="vehicleTypes"
								optionValue="value"
							/>
						</div>

						<div>
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								{{ t('vehicleNumber') }}
							</div>
							<InputNumber
								v-model="vehicle.number"
								:disabled="status"
								:max="9"
								:min="1"
								mode="decimal"
								:pt="{
									input: {
										style: { widht: '100px' }
									}
								}"
								showButtons
							/>
						</div>
					</div>
				</Popover>
			</div>

			<div class="tw3-flex tw3-flex-col tw3-gap-2">
				<div class="tw3-mx-auto">
					<Image
						alt="Car Image"
						height="96px"
						:src="imgUrl"
						width="160px"
						@error="handleError"
					/>
				</div>

				<div class="tw3-font-bold">
					{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
				</div>

				<div>
					<div class="tw3-flex tw3-h-6 tw3-text-sm tw3-content-end tw3-gap-3">
						<div class="tw3-text-slate-500 tw3-w-6 tw3-font-medium tw3-mb-1">
							{{ t('type') }}:
						</div>
						<div class="tw3-flex tw3-ml-1">
							<div class="tw3-tracking-widest">{{ vehicle.style }}</div>
						</div>
						<Tag
							v-tooltip.top="'#2204'"
							class="tw3-ml-auto tw3-uppercase"
							icon="pi pi-check"
							severity="info"
							>nags</Tag
						>
					</div>

					<div
						v-if="vehicle.plateNumber"
						class="tw3-flex tw3-items-center tw3-h-6 tw3-text-sm tw3-gap-3"
					>
						<div class="tw3-text-slate-500 tw3-w-6 tw3-font-medium tw3-mb-1">
							{{ t('plate') }}:
						</div>
						<div class="tw3-flex tw3-ml-1">
							<div class="tw3-tracking-widest">{{ vehicle.plateNumber }}</div>
						</div>
						<div>
							<Button
								aria-label="Copy"
								icon="pi pi-copy"
								rounded
								:style="{ height: '21px', width: '21px' }"
								text
								@click="copy(vehicle.plateNumber)"
							/>
						</div>
					</div>

					<div
						v-if="vehicle.vin"
						class="tw3-flex tw3-items-center tw3-h-6 tw3-text-sm tw3-gap-3"
					>
						<div class="tw3-text-slate-500 tw3-w-6 tw3-font-medium">
							<span v-tooltip.top="t('vinTooltip')">{{ t('vin') }}:</span>
						</div>
						<div class="tw3-flex tw3-ml-1">
							<span class="tw3-tracking-widest">{{ vehicle.vin }}</span>
						</div>
						<div>
							<Button
								aria-label="Copy"
								icon="pi pi-copy"
								rounded
								:style="{ height: '21px', width: '21px' }"
								text
								@click="copy(vehicle.vin)"
							/>
						</div>
					</div>
				</div>
			</div>
		</Fieldset>
	</div>
</template>
