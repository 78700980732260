<script setup lang="ts">
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import * as R from 'ramda';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';

import { taxesHeader } from '@/constants/editCustomer';
import { getConsumerName } from '@/scripts/helpers/consumer.helpers';
import useCustomerEditStore from '@/store/customerEdit/customerEditStore';
import { Customer, CustomerRates } from '@/types/customer';

const store = useCustomerEditStore();

const nagsSelector = ref<any>();
const shopId = ref(0);
const shopIndex = computed(() =>
	store.customer.state.consumer_shop_settings.findIndex(
		v => v.shop_id === shopId.value
	)
);

const shopRatesToSelect = computed(() => {
	const shopRatesToCompare = store.shopRates.state ? store.shopRates.state : [];

	// consumers may have custom individual rates already, this maintains those
	if (
		!store.customer.state.consumer_shop_settings[shopIndex.value].rates?.id ||
		(
			store.customer.state.consumer_shop_settings[shopIndex.value]
				.rates as CustomerRates
		).id <= 0
	) {
		return shopRatesToCompare;
	}
	let foundMatch = false;
	for (let i = 0; i < shopRatesToCompare.length; i++) {
		if (
			shopRatesToCompare[i].id ===
			store.customer.state.consumer_shop_settings[shopIndex.value].rates?.id
		) {
			foundMatch = true;
		}
	}
	if (foundMatch) {
		return shopRatesToCompare;
	} else {
		const ratesToReturn = R.clone(shopRatesToCompare);
		const rateToAdd = R.clone(
			store.customer.state.consumer_shop_settings[shopIndex.value].rates
		) as CustomerRates;
		rateToAdd.id = store.customer.state.consumer_shop_settings[shopIndex.value]
			.rates?.id as number;
		rateToAdd.name = 'Custom Consumer Rate';
		ratesToReturn.push(rateToAdd);
		return ratesToReturn;
	}
});

const updateValue = (field: keyof Customer, value: any) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};

const updateRates = (val: any) => {
	updateValue(
		'consumer_shop_settings',
		store.customer.state.consumer_shop_settings.map(v => {
			if (v.shop_id === shopId.value) {
				const rates = store.shopRates.state.find(
					(v: CustomerRates) => v.id === val
				) as unknown as CustomerRates;
				return Object.assign(v, { rates: rates ?? null });
			}
			return v;
		})
	);
};

const updatePriceLevel = (val: any) => {
	updateValue(
		'consumer_shop_settings',
		store.customer.state.consumer_shop_settings.map(v => {
			if (v.shop_id === shopId.value) {
				return Object.assign(v, { pricelevel_id: val });
			}
			return v;
		})
	);
};

const addShopToConsumerSettings = (shopId: number) => {
	const consumerShopSettingToAdd = {
		override_allowed_shop: 0,
		pricelevel_id: null,
		rates: null,
		shop_id: shopId
	};
	updateValue('consumer_shop_settings', [
		...store.customer.state.consumer_shop_settings,
		consumerShopSettingToAdd
	]);
};

const hydrateNags = (isCreate?: boolean) => {
	nagsSelector.value.hide();
	const newEvent = new CustomEvent('hydrateNags', {
		detail: {
			isCreate,
			rate: isCreate
				? {}
				: store.customer.state.consumer_shop_settings[shopIndex.value].rates,
			shopRates: store.shopRates.state,
			shopId:
				store.customer.state.consumer_shop_settings[shopIndex.value].shop_id,
			defaultName:
				getConsumerName({
					company_name: store.customer.state.company_name,
					name: store.name
				}) ?? '-'
		}
	});
	window.dispatchEvent(newEvent);
};

const hydrateNagsFromVue2 = (event: any) => {
	if (event.detail.isCreate) {
		store.shopRates.state = [...store.shopRates.state, event.detail.newRate];
	} else {
		store.shopRates.state = store.shopRates.state.map(v => {
			if (v.id === event.detail.newRate.id) return event.detail.newRate;
			else return v;
		});
	}
	store.customer.state.consumer_shop_settings[shopIndex.value].rates =
		event.detail.newRate;
};

onMounted(async () => {
	window.addEventListener('returnNagsToVue3', hydrateNagsFromVue2);
	if (!store.shopsByOrganization.state.length) {
		await store.shopsByOrganization.execute(0);
	}
	shopId.value = store.customer.state.consumer_shop_settings[0]?.shop_id
		? store.customer.state.consumer_shop_settings[0].shop_id
		: store.shopsByOrganization.state[0].id;
	if (shopIndex.value === -1) {
		addShopToConsumerSettings(shopId.value);
	}
	const promises = [];
	if (!store.shopPricelevels.state.length) {
		promises.push(store.shopPricelevels.execute(0, { id: shopId.value }));
	}
	if (!store.shopRates.state.length) {
		promises.push(store.shopRates.execute(0, { id: shopId.value }));
	}
	if (!store.shopTaxes.state.length) {
		promises.push(store.shopTaxes.execute(0, { id: shopId.value }));
	}
	await Promise.all(promises);
});

watch(
	() => shopId.value,
	async () => {
		await nextTick();
		if (shopIndex.value === -1) {
			addShopToConsumerSettings(shopId.value);
		}
	}
);

onUnmounted(() =>
	window.removeEventListener('returnNagsToVue3', hydrateNagsFromVue2)
);
</script>

<template>
	<div class="tw3-w-full tw3-flex tw3-flex-col tw3-gap-4">
		<div class="tw3-w-full tw3-max-w-64">
			<div
				class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
			>
				Shop
			</div>
			<Select
				v-model="shopId"
				class="tw3-w-full"
				:loading="store.shopsByOrganization.isLoading"
				optionLabel="name"
				:options="store.shopsByOrganization.state"
				optionValue="id"
				placeholder="Select item"
			/>
		</div>
		<div v-if="shopIndex !== -1" class="tw3-flex tw3-items-center tw3-gap-4">
			<div class="tw3-w-full tw3-max-w-64">
				<div class="tw3-flex tw3-justify-between">
					<div
						class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
					>
						Default Nags Pricing
					</div>
					<Button
						v-if="store.customer.state.consumer_shop_settings[shopIndex].rates"
						label="Edit"
						rounded
						severity="secondary"
						size="small"
						text
						@click="hydrateNags()"
					/>
				</div>
				<Select
					ref="nagsSelector"
					class="tw3-w-full"
					filter
					:loading="store.shopRates.isLoading"
					:modelValue="
						store.customer.state.consumer_shop_settings[shopIndex].rates?.id
					"
					optionLabel="name"
					:options="shopRatesToSelect"
					optionValue="id"
					placeholder="Select item"
					showClear
					@update:model-value="updateRates"
				>
					<template #footer>
						<Button
							class="tw3-w-full tw3-my-2"
							icon="pi pi-plus"
							label="Add New"
							severity="secondary"
							size="small"
							text
							@click="hydrateNags(true)"
						/>
					</template>
				</Select>
			</div>
			<div class="tw3-w-full tw3-max-w-64">
				<div
					class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					Default Price Level
				</div>
				<Select
					class="tw3-w-full"
					filter
					:loading="store.shopPricelevels.isLoading"
					:modelValue="
						store.customer.state.consumer_shop_settings[shopIndex].pricelevel_id
					"
					optionLabel="name"
					:options="store.shopPricelevels.state"
					optionValue="id"
					placeholder="Select item"
					showClear
					@update:model-value="updatePriceLevel"
				/>
			</div>
		</div>
		<DataTable
			class="tw3-w-full"
			dataKey="id"
			:selection="store.customer.state.taxes"
			:value="store.shopTaxes.isLoading ? new Array(4) : store.shopTaxes.state"
			@update:selection="v => updateValue('taxes', [...v])"
		>
			<Column v-if="!store.shopTaxes.isLoading" selectionMode="multiple" />
			<Column
				v-for="column of taxesHeader"
				:key="column.key"
				:field="column.key"
				:header="column.title"
			>
				<template #body="{ data }">
					<Skeleton v-if="store.shopTaxes.isLoading" />
					<div
						v-else
						class="tw3-flex"
						:class="{
							'tw3-justify-center':
								column.key === 'for_materials' || column.key === 'for_labor',
							'tw3-justify-end': column.key === 'percentage'
						}"
					>
						<i
							v-if="
								column.key === 'for_materials' || column.key === 'for_labor'
							"
							:class="!!data[column.key] ? 'pi pi-check' : 'pi pi-times'"
						/>
						<span v-else class="tw3-self-end">{{ data[column.key] }}</span>
					</div>
				</template>
			</Column>
		</DataTable>
	</div>
</template>
