import { CustomerTable } from '@/types/customer';

import CORE_API from '../core';

import type { CustomerRequestParams, CustomerResponse } from './types';

export const CUSTOMER_URL = '/unum/consumer/list';
export const EXPORT_CUSTOMER_URL = '/unum/consumer/list/export';

export const getCustomers = async (
	params: CustomerRequestParams
): Promise<CustomerResponse<CustomerTable>> => {
	try {
		const response = await CORE_API.post<CustomerResponse<CustomerTable>>(
			CUSTOMER_URL,
			params
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const exportCustomers = async (
	params: CustomerRequestParams
): Promise<{
	csvExport: string;
	csvUrl: string[];
}> => {
	try {
		const response = await CORE_API.post<{
			csvExport: string;
			csvUrl: string[];
		}>(EXPORT_CUSTOMER_URL, params);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
