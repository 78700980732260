import type { NormalizedQuote, Quote } from './types';

export const normazileQuotes = (data: Quote[]): NormalizedQuote[] => {
	return data.map((quote: Quote) => ({
		amount: quote.amount,
		customerName: quote.customer_name,
		customerPhone: quote.customer_phone,
		followupDate: quote.f_followup_date,
		gbCsrId: quote.csr,
		id: quote.id,
		quoteDate: quote.f_quote_date,
		vehicle: quote.vehicle_full
	}));
};
