import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { reactive, ref, watchEffect } from 'vue';

import { exportCustomers, getCustomers } from '@/api/customers';
import {
	customersFilters,
	ITEMS_PER_REQUEST,
	NormalizedCustomer,
	TABLE_ID
} from '@/constants/customers';
import { CustomersFiltersWithMetadata } from '@/types/customer';
import { pickNonEmptyPairs } from '@/utils/object';

const useCustomersStore = defineStore('customers', () => {
	const toast = useToast();

	const loadingMore = ref(false);
	const exportingLoading = ref(false);
	const preventFetchMore = ref(false);
	const sort = reactive({ desc: 'desc', field: 'id' });
	const filters = ref<CustomersFiltersWithMetadata<NormalizedCustomer>>({
		...customersFilters
	});

	const customers = useAsyncState(
		async (args: any) => {
			const searchFilters = pickNonEmptyPairs(
				filters?.value as Record<string, any>,
				'val',
				true
			);

			const table = document.getElementById(TABLE_ID);
			(table as HTMLElement).scrollTop = 0;

			const data = await getCustomers({
				...args,
				filters: searchFilters
			}).catch(error => {
				toast.add({
					detail: error,
					life: 3000,
					severity: 'error',
					summary: 'Error Message'
				});
				return { count: 0, customers: [] };
			});
			preventFetchMore.value = data.customers.length < ITEMS_PER_REQUEST;
			return data;
		},
		{ count: 0, customers: [] },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const fetchCustomersMore = async () => {
		loadingMore.value = true;
		const args = composeGetCustomersArgs(
			customers.state.value.customers.length
		);
		const searchFilters = pickNonEmptyPairs(
			filters?.value as Record<string, any>,
			'val',
			true
		);

		const data = await getCustomers({
			...args,
			filters: searchFilters
		}).catch(error => {
			toast.add({
				detail: error,
				life: 3000,
				severity: 'error',
				summary: 'Error Message'
			});
			return { customers: [] };
		});
		preventFetchMore.value = data.customers.length < ITEMS_PER_REQUEST;
		customers.state.value = {
			count: customers.state.value.count,
			customers: [...customers.state.value.customers, ...data.customers]
		};
		loadingMore.value = false;
	};

	const handleSort = (sortData: { field: string | null; desc: boolean }) => {
		if (sortData.field === null || sortData.field.trim() === '') {
			return;
		}

		sort.field = sortData.field;
		sort.desc = sortData.desc ? 'desc' : 'asc';
	};

	const composeGetCustomersArgs = (offset: number) => {
		return {
			filters: filters.value,
			offset,
			getCount: false,
			sort: {
				sortBy: sort.field,
				sortOrder: sort.desc
			}
		};
	};

	const exportData = async () => {
		exportingLoading.value = true;
		const args = composeGetCustomersArgs(0);
		const searchFilters = pickNonEmptyPairs(
			filters?.value as Record<string, any>,
			'val',
			true
		);
		try {
			const data = await exportCustomers({
				...args,
				filters: searchFilters
			});

			if (data.csvExport === 'yes' && data.csvUrl && data.csvUrl.length > 0) {
				window.open(data.csvUrl[0], '_blank');
			}
		} catch (error) {
			toast.add({
				detail: error,
				life: 3000,
				severity: 'error',
				summary: 'Error exporting customer data'
			});
		}
		exportingLoading.value = false;
	};

	watchEffect(async () => {
		await customers.execute(0, composeGetCustomersArgs(0));
	});

	return {
		composeGetCustomersArgs,
		exportData,
		exportingLoading,
		filters,
		fetchCustomersMore,
		preventFetchMore,
		handleSort,
		loadingMore,
		customers,
		sort
	};
});

export default useCustomersStore;
