<script setup lang="ts">
import { computed, ref } from 'vue';

import AddressDialog from '@/components/core/addressDialog/AddressDialog.vue';
import {
	getAvatarBg,
	getConsumerName,
	getInitials
} from '@/scripts/helpers/consumer.helpers';
import useCustomerEditStore from '@/store/customerEdit/customerEditStore';
import { Customer } from '@/types/customer';

const showAddress = ref(false);

const store = useCustomerEditStore();

const name = computed(() =>
	getConsumerName({
		company_name: store.customer.state.company_name,
		name: store.name
	})
);
const avatarBg = computed(() => getAvatarBg(name.value));
const initials = computed(() =>
	getInitials({ ...store.customer.state, name: store.name })
);

const toggleBadge = (field: keyof Customer, value: number | string) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};
</script>

<template>
	<div
		class="tw3-w-64 tw3-p-5 tw3-pb-6 tw3-pt-12 tw3-flex-shrink-0 tw3-rounded-l-xl tw3-relative"
	>
		<AddressDialog
			v-if="store.address && store.address.full_address"
			v-model:show="showAddress"
			:fullAddress="store.address.full_address"
			:lat="store.address.lat"
			:lng="store.address.lng"
		/>
		<!-- Flagged -->
		<div
			v-tooltip.top="
				store.customer.state.flagged ? 'Remove Flagged' : 'Make Flagged'
			"
			class="tw3-absolute tw3-top-4 tw3-right-4 tw3-cursor-pointer hover:tw3-bg-gray-200 tw3-p-4 tw3-rounded-full"
			@click="toggleBadge('flagged', store.customer.state.flagged ? 0 : 1)"
		>
			<i
				:class="store.customer.state.flagged ? 'pi pi-flag-fill' : 'pi pi-flag'"
			/>
		</div>

		<!-- Avatar -->
		<div class="tw3-flex tw3-items-center tw3-justify-center">
			<div
				class="tw3-w-36 tw3-h-36 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center"
				:class="avatarBg"
			>
				<div
					class="tw3-w-32 tw3-h-32 tw3-rounded-full tw3-flex tw3-items-center tw3-justify-center tw3-bg-white"
				>
					<span class="tw3-text-6xl tw3-text-gray-600">{{ initials }}</span>
				</div>
			</div>
		</div>

		<!-- Name -->
		<div
			class="tw3-text-xl tw3-font-medium tw3-mt-5 tw3-text-slate-500 tw3-text-center tw3-break-normal tw3-truncate"
		>
			{{ name ?? '-' }}
		</div>

		<hr class="tw3-my-6" />

		<!-- Info -->
		<div class="tw3-space-y-6 tw3-text-base tw3-text-slate-500 tw3-font-medium">
			<!-- ID -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i class="pi pi-id-card" />
				<div :class="{ 'tw3-text-gray-300': !store.customer.state.id }">
					{{ store.customer.state.id || 'ID' }}
				</div>
			</div>

			<!-- Commercial -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i
					:class="
						!store.customer.state.is_commercial ||
						store.customer.state.is_insurance
							? 'pi pi-briefcase'
							: 'pi pi-building'
					"
				/>

				<div>
					<div
						v-if="store.customer.state.is_insurance"
						class="tw3-px-2 t-py-0.5 tw3-rounded tw3-text-white tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md tw3-bg-indigo-500"
					>
						Insurance
					</div>
					<div
						v-else
						class="tw3-px-2 t-py-0.5 tw3-rounded tw3-text-white tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md"
						:class="
							store.customer.state.is_commercial
								? 'tw3-bg-yellow-500'
								: 'tw3-bg-cyan-500'
						"
						@click="
							toggleBadge(
								'is_commercial',
								store.customer.state.is_commercial ? 0 : 1
							)
						"
					>
						{{ store.customer.state.is_commercial ? 'Comm' : 'Retail' }}
					</div>
				</div>
			</div>

			<!-- Status -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i
					:class="
						!store.customer.state.status ||
						store.customer.state.status === 'active'
							? 'pi pi-check-circle'
							: 'pi pi-times-circle'
					"
				/>

				<div
					class="tw3-px-2 tw3-py-0.5 tw3-rounded tw3-text-white tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md"
					:class="
						!store.customer.state.status ||
						store.customer.state.status === 'active'
							? 'tw3-bg-green-500'
							: 'tw3-bg-gray-500'
					"
					@click="
						toggleBadge(
							'status',
							!store.customer.state.status ||
								store.customer.state.status === 'active'
								? 'disabled'
								: 'active'
						)
					"
				>
					{{
						!store.customer.state.status ||
						store.customer.state.status === 'active'
							? 'Active'
							: 'Inactive'
					}}
				</div>
			</div>

			<!-- Primary Phone -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i class="pi pi-phone" />

				<a
					v-if="store.phone && store.phone.number"
					:href="'tel:' + store.phone.number"
				>
					{{ store.phone.number }}
				</a>
				<div v-else class="tw3-text-gray-300">Telephone</div>
			</div>

			<!-- Primary Email -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i class="pi pi-envelope" />

				<a
					v-if="store.email && store.email.email"
					:href="'mailto:' + store.email.email"
				>
					{{ store.email.email }}
				</a>
				<div v-else class="tw3-text-gray-300">Email</div>
			</div>

			<!-- Primary Address -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i class="pi pi-map-marker" />

				<a
					v-if="store.address && store.address.full_address"
					class="tw3-break-normal"
					href="#"
					@click="showAddress = true"
				>
					{{ store.address.full_address }}
				</a>
				<div v-else class="tw3-text-gray-300">Address</div>
			</div>

			<!-- Website -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i class="pi pi-globe" />

				<a
					v-if="store.customer.state.website"
					class="tw3-break-normal"
					:href="store.customer.state.website"
					target="_blank"
				>
					{{ store.customer.state.website }}
				</a>
				<div v-else class="tw3-text-gray-300">Website</div>
			</div>

			<!-- Fax -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i class="pi pi-inbox" />

				<div v-if="store.customer.state.fax" class="tw3-break-normal">
					{{ store.customer.state.fax }}
				</div>
				<div v-else class="tw3-text-gray-300">Fax</div>
			</div>

			<!-- Exempt -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i
					:class="
						store.customer.state.tax_exempt ? 'pi pi-star-fill' : 'pi pi-star'
					"
				/>

				<div
					v-tooltip.top="'Sales tax exempt'"
					class="tw3-px-2 tw3-py-0.5 tw3-rounded tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md tw3-border"
					:class="
						store.customer.state.tax_exempt
							? 'tw3-border-transparent tw3-bg-gray-400 tw3-text-white'
							: ' tw3-border-gray-400 tw3-text-gray-400'
					"
					@click="
						toggleBadge('tax_exempt', store.customer.state.tax_exempt ? 0 : 1)
					"
				>
					Exempt
				</div>
			</div>

			<!-- PO Required -->
			<div class="tw3-flex tw3-space-x-6 tw3-items-center">
				<i class="pi pi-inbox" />

				<div
					v-tooltip.top="'PO number required to invoice customer'"
					class="tw3-px-2 tw3-py-0.5 tw3-rounded tw3-text-xs tw3-mr-3 tw3-cursor-pointer tw3-shadow-md tw3-border"
					:class="
						store.customer.state.po_required
							? 'tw3-border-transparent tw3-bg-sky-700 tw3-text-white'
							: ' tw3-border-sky-700 tw3-text-sky-700'
					"
					@click="
						toggleBadge('po_required', store.customer.state.po_required ? 0 : 1)
					"
				>
					PO Required
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.notes-container {
	max-height: 105px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	line-clamp: 5;
	white-space: normal;
}
</style>
