import CORE_API from '../core';

import {
	InsuranceProvider,
	InsuranceRequest,
	InsuranceResponse
} from './types';

const INSURANCE_URL = '/unum/insurance/insurer';

//TODO should be global? Provider Name?

export const getInsurances = async (
	params: InsuranceRequest
): Promise<InsuranceProvider[]> => {
	try {
		const response = await CORE_API.get<InsuranceResponse>(INSURANCE_URL, {
			params
		});

		return response.data.insurer;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
