import {
	ApolloClient,
	ApolloLink,
	createHttpLink,
	InMemoryCache
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import cfg from '@/config';
import { getGbAuthValueFromLocalStorage } from '@/utils/localStorage';

const httpLink = createHttpLink({
	uri: `${cfg.uri}/apollo/job-details`
});

const authLink = setContext(async (_, { headers }) => {
	return {
		headers: {
			...headers,
			authorization: `Bearer ${getGbAuthValueFromLocalStorage('access_token')}`
		}
	};
});

const errorLink = onError(({ graphQLErrors, operation }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(error => {
			console.error(`${operation.operationName}: ${error.message}`);
		});
	}
});

const httpLinkDefault = authLink.concat(httpLink);

const cache = new InMemoryCache();
const linkDefault = ApolloLink.from([errorLink, httpLinkDefault]);
export default new ApolloClient({
	cache,
	connectToDevTools: cfg.isDev,
	link: linkDefault
});
