import { cloneDeep } from '@apollo/client/utilities';
import { useAsyncState } from '@vueuse/core';
import { isEmpty } from 'lodash-es';
import { defineStore, storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';
import * as R from 'ramda';
import { computed, reactive, ref } from 'vue';

import useApolloClient from '@/app/apollo';
import { SHOP_PIECES_QUERIES } from '@/entities/shop/lib/graphql/queries/shop-pieces.query';
import { SHOPS_ORGANIZATION_QUERY } from '@/entities/shop/lib/graphql/queries/shops.query';
import { useUserStore } from '@/entities/user/lib/store';
import { removeTypenameFields } from '@/shared/helpers';

import { getBillTo, getEdiPartners, getPreviousJobs } from '../api';
import { lynxFields, sgcFields } from '../contants';
import {
	CREATE_CONSUMER_MUTATION,
	UPDATE_CONSUMER_MUTATION
} from '../graphql/mutation/consumer.mutation';
import { GET_CONSUMER_QUERY } from '../graphql/queries/consumer.query';
import { GB_VENDOR_DROPDOWN_QUERIES } from '../graphql/queries/gb-vendor.query';
import { Customer, CustomerRates, CustomerTaxes, Option } from '../types';

export const useCustomerEditStore = defineStore('customerEdit', () => {
	const client = useApolloClient;
	const { user } = storeToRefs(useUserStore());
	const toast = useToast();

	const shops = reactive(user.value.shops || []);
	const selectedShop = ref(shops.length ? shops[0].id : null);

	const customerStateBeforeChanges = ref<Customer | undefined>(undefined);
	const vue2FieldName = ref<string>();
	const hydrateLoading = ref(false);

	const primaryNameIndex = ref(-1);
	const primaryPhoneIndex = ref(-1);
	const secondaryPhoneIndex = ref(-1);
	const primaryEmailIndex = ref(-1);
	const primaryAddressIndex = ref(-1);

	const name = computed(() =>
		customer.state.value.names
			? customer.state.value.names[primaryNameIndex.value]
			: null
	);
	const phone = computed(() =>
		customer.state.value.phones
			? customer.state.value.phones[primaryPhoneIndex.value]
			: null
	);
	const secondary_phone = computed(() =>
		customer.state.value.phones
			? customer.state.value.phones[secondaryPhoneIndex.value]
			: null
	);
	const email = computed(() =>
		customer.state.value.emails
			? customer.state.value.emails[primaryEmailIndex.value]
			: null
	);

	const address = computed(() =>
		customer.state.value.addresses
			? customer.state.value.addresses[primaryAddressIndex.value]
			: null
	);

	const errors = ref<Record<string, Record<string, string | undefined>>>({});
	const isValid = computed(() => {
		for (const key in errors.value) {
			if (!isEmpty(errors.value[key])) return false;
		}
		return true;
	});

	const customer = useAsyncState(
		async (args: any) => {
			const { data } = await client.query<{ consumer: Customer }>({
				query: GET_CONSUMER_QUERY,
				variables: { id: args.id },
				fetchPolicy: 'network-only'
			});
			const customer = cloneDeep(data.consumer);

			const names = customer.names.filter(v => !!v.first);
			const phones = customer.phones.filter(v => !!v.number);
			const emails = customer.emails.filter(v => !!v.email);
			const addresses = customer.addresses.filter(v => !!v.full_address);

			if (data.consumer.name) {
				const index = names.findIndex(v => v.id === data.consumer.name?.id);
				if (index === -1) {
					names.unshift(data.consumer.name);
					primaryNameIndex.value = 0;
				} else primaryNameIndex.value = index;
			}
			if (data.consumer.phone) {
				const index = phones.findIndex(v => v.id === data.consumer.phone?.id);
				if (index === -1) {
					phones.unshift(data.consumer.phone);
					primaryPhoneIndex.value = 0;
				} else primaryPhoneIndex.value = index;
			}
			if (data.consumer.secondary_phone) {
				const index = phones.findIndex(
					v => v.id === data.consumer.secondary_phone?.id
				);
				if (index === -1) {
					phones.unshift(data.consumer.secondary_phone);
					secondaryPhoneIndex.value = -1;
				} else secondaryPhoneIndex.value = index;
			}
			if (data.consumer.email) {
				const index = emails.findIndex(v => v.id === data.consumer.email?.id);
				if (index === -1) {
					emails.unshift(data.consumer.email);
					primaryEmailIndex.value = 0;
				} else primaryEmailIndex.value = index;
			}
			if (data.consumer.address) {
				const index = addresses.findIndex(
					v => v.id === data.consumer.address?.id
				);
				if (index === -1) {
					addresses.unshift(data.consumer.address);
					primaryAddressIndex.value = 0;
				} else primaryAddressIndex.value = index;
			}

			customerStateBeforeChanges.value = {
				...customer,
				emails,
				phones,
				addresses,
				names
			};
			return customerStateBeforeChanges.value;
		},
		{} as Customer,
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const hydrateCustomer = async () => {
		if (!isValid.value) {
			toast.add({
				detail: 'Please fill in all required fields.',
				life: 3000,
				severity: 'error',
				summary: 'Error Message'
			});
			return;
		}
		if (
			!customer.state.value.is_commercial &&
			!customer.state.value.is_insurance
		) {
			customer.state.value = {
				...customer.state.value,
				company_name: '',
				website: null,
				fax: null
			};
		}
		if (
			(customer.state.value.is_commercial ||
				customer.state.value.is_insurance) &&
			!customer.state.value.company_name
		) {
			toast.add({
				detail: 'The company name must be filled.',
				life: 3000,
				severity: 'error',
				summary: 'Error Message'
			});
			return;
		}
		if (
			!customer.state.value.is_commercial &&
			!customer.state.value.is_insurance &&
			!name.value
		) {
			toast.add({
				detail: 'At least one name must be added.',
				life: 3000,
				severity: 'error',
				summary: 'Error Message'
			});
			return;
		}
		hydrateLoading.value = true;
		try {
			const customerFields = removeTypenameFields({
				...customer.state.value,
				address: address.value,
				email: email.value,
				name: name.value,
				phone: phone.value,
				secondary_phone: secondary_phone.value
			} as Customer);

			const { data } = await client.mutate<
				{ updateConsumer: Customer } | { createConsumer: Customer }
			>({
				mutation: customerFields.id
					? UPDATE_CONSUMER_MUTATION
					: CREATE_CONSUMER_MUTATION,
				variables: {
					consumer: customerFields,
					...(customerFields.id ? { consumer_id: customerFields.id } : {})
				}
			});
			if (data) {
				customer.state.value =
					(data as { updateConsumer: Customer }).updateConsumer ||
					(data as { createConsumer: Customer }).createConsumer;
				customerStateBeforeChanges.value = customer.state.value;
				toast.add({
					detail: 'Customer successfully saved.',
					life: 3000,
					severity: 'success',
					summary: 'Success Message'
				});
			}
			if (!vue2FieldName.value) {
				return;
			}
			const newEvent = new CustomEvent('returnCustomerData', {
				detail: {
					isCreated: !!(data as { createConsumer: Customer }).createConsumer,
					field: vue2FieldName.value,
					data: customer.state.value
				}
			});
			window.dispatchEvent(newEvent);
			vue2FieldName.value = undefined;
		} catch (e) {
			console.log(e);
		} finally {
			hydrateLoading.value = false;
		}
	};

	const updateErrors = async (
		field: keyof Customer | 'tradingPartner' | 'partnerProgramId',
		errObj: Record<string, string | undefined>
	) => {
		errors.value[field] = errObj;
	};

	const onClose = () => {
		errors.value = {};
		salesrepOptions.state.value = [];
		techOptions.state.value = [];
		shopsByOrganization.state.value = [];
		shopPricelevels.state.value = [];
		shopRates.state.value = [];
		shopTaxes.state.value = [];
	};

	const salesrepOptions = useAsyncState(
		async () => {
			const response = await client.query<{ gb_salesreps: Option[] }>({
				query: GB_VENDOR_DROPDOWN_QUERIES.gb_salesrep,
				variables: { organization_id: customer.state.value.organization_id }
			});
			return response.data.gb_salesreps;
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const techOptions = useAsyncState(
		async () => {
			const response = await client.query<{ gb_techs: Option[] }>({
				query: GB_VENDOR_DROPDOWN_QUERIES.gb_tech,
				variables: { organization_id: customer.state.value.organization_id }
			});
			return response.data.gb_techs;
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const shopsByOrganization = useAsyncState(
		async () => {
			const response = await client.query<{ shopsByOrganization: Option[] }>({
				query: SHOPS_ORGANIZATION_QUERY,
				variables: { organization_id: customer.state.value.organization_id }
			});
			return response.data.shopsByOrganization;
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const shopPricelevels = useAsyncState(
		async (args: any) => {
			const response = await client.query<{ shopPricelevels: Option[] }>({
				query: SHOP_PIECES_QUERIES.pricelevels,
				variables: { shop_id: args.id }
			});
			return response.data.shopPricelevels;
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const shopRates = useAsyncState(
		async (args: any) => {
			const response = await client.query<{ shopRates: CustomerRates[] }>({
				query: SHOP_PIECES_QUERIES.rates,
				variables: { shop_id: args.id },
				fetchPolicy: 'network-only'
			});
			return response.data.shopRates;
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const shopTaxes = useAsyncState(
		async (args: any) => {
			const response = await client.query<{ shopTaxes: CustomerTaxes[] }>({
				query: SHOP_PIECES_QUERIES.tax,
				variables: { shop_id: args.id }
			});
			return response.data.shopTaxes;
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const previousJobs = useAsyncState(
		() => getPreviousJobs(customer.state.value.id),
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const ediPartners = useAsyncState(
		(args: any) => getEdiPartners(args.id),
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const billsTo = useAsyncState(
		(args: any) =>
			getBillTo(
				customer.state.value.organization_id,
				args.first,
				args.search ?? ''
			),
		{ count: 0, rows: [] },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const fetchBillMore = async (first: number, search?: string) => {
		const data = await getBillTo(
			customer.state.value.organization_id,
			first,
			search ?? ''
		);
		billsTo.state.value = {
			count: data.count,
			rows: R.uniq([...billsTo.state.value.rows, ...data.rows])
		};
	};

	const setSgc = () => {
		customer.state.value = {
			...customer.state.value,
			...(sgcFields as unknown as Customer)
		};
		primaryPhoneIndex.value = 0;
		secondaryPhoneIndex.value = -1;
		primaryEmailIndex.value = 0;
		primaryAddressIndex.value = 0;
	};

	const setLynx = () => {
		customer.state.value = {
			...customer.state.value,
			...(lynxFields as unknown as Customer)
		};
		primaryPhoneIndex.value = 0;
		secondaryPhoneIndex.value = -1;
		primaryEmailIndex.value = 0;
		primaryAddressIndex.value = 0;
	};

	const setNone = () => {
		customer.state.value = {
			...customer.state.value,
			addresses: [],
			website: null,
			phones: [],
			fax: null,
			emails: []
		};
		primaryPhoneIndex.value = 0;
		secondaryPhoneIndex.value = -1;
		primaryEmailIndex.value = 0;
		primaryAddressIndex.value = 0;
	};

	return {
		address,
		billsTo,
		customer,
		customerStateBeforeChanges,
		ediPartners,
		email,
		errors,
		fetchBillMore,
		name,
		phone,
		previousJobs,
		primaryAddressIndex,
		primaryEmailIndex,
		primaryNameIndex,
		primaryPhoneIndex,
		secondaryPhoneIndex,
		salesrepOptions,
		selectedShop,
		setSgc,
		setLynx,
		setNone,
		shopPricelevels,
		shopRates,
		shopTaxes,
		shops,
		shopsByOrganization,
		techOptions,
		hydrateCustomer,
		updateErrors,
		hydrateLoading,
		vue2FieldName,
		onClose
	};
});
