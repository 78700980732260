<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputMask from 'primevue/inputmask';
import ToggleSwitch from 'primevue/toggleswitch';
import { useFieldArray, useForm } from 'vee-validate';
import { nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import { CustomerPhone } from '@/entities/customer/lib/types';

const ZOD_PHONES = 'phones';

const props = defineProps<{
	modelValue: CustomerPhone[];
}>();

const emit = defineEmits<{
	(e: 'update:modelValue', c: CustomerPhone[]): void;
}>();

const { t } = useI18n();

const store = useCustomerEditStore();

const validationSchema = toTypedSchema(
	zod.object({
		[ZOD_PHONES]: zod.array(
			zod.object({
				number: zod
					.string({ message: t('required') })
					.min(14, t('errorNumber'))
					.max(14, t('errorNumber'))
			})
		)
	})
);

const { errors, setValues } = useForm({
	initialValues: { [ZOD_PHONES]: props.modelValue },
	validationSchema
});

const { fields } = useFieldArray<Omit<CustomerPhone, 'id'>>(ZOD_PHONES);

const add = () => {
	if (!props.modelValue.length) store.primaryPhoneIndex = 0;
	emit('update:modelValue', [
		...props.modelValue,
		{ number: '' } as CustomerPhone
	]);
};

const updatePrimary = (index: number) => {
	store.primaryPhoneIndex = index;
	if (store.secondaryPhoneIndex === index) {
		store.secondaryPhoneIndex = -1;
	}
};

const updateSecondary = (index: number) => {
	if (fields.value.length > 1) {
		store.secondaryPhoneIndex = index;
		if (store.primaryPhoneIndex === index) {
			store.primaryPhoneIndex = index === 0 ? 1 : 0;
		}
	}
};

const updateVal = (
	index: number,
	field: keyof CustomerPhone,
	val: string | null
) => {
	emit(
		'update:modelValue',
		props.modelValue.map((v, vIndex) => {
			if (vIndex === index) v[field] = val as never;
			return v;
		})
	);
};

const removeVal = async (index: number) => {
	emit(
		'update:modelValue',
		props.modelValue.filter((_, i) => i !== index)
	);
	await nextTick();
	if (store.primaryPhoneIndex === index) {
		store.primaryPhoneIndex =
			store.secondaryPhoneIndex === 0 && props.modelValue.length > 1 ? 1 : 0;
		if (props.modelValue.length <= 1) {
			store.secondaryPhoneIndex = -1;
		}
	} else if (store.primaryPhoneIndex > index) {
		store.primaryPhoneIndex -= 1;
	}

	if (store.secondaryPhoneIndex === index) {
		store.secondaryPhoneIndex = -1;
	} else if (store.secondaryPhoneIndex > index) {
		store.secondaryPhoneIndex -= 1;
		if (store.secondaryPhoneIndex === store.primaryPhoneIndex) {
			store.secondaryPhoneIndex = -1;
		}
	}
};

watch(
	() => props.modelValue,
	() => {
		setValues({ [ZOD_PHONES]: props.modelValue });
	}
);

watch(
	() => errors.value,
	() => store.updateErrors('phones', errors.value)
);
</script>

<template>
	<DataTable
		class="ui-data-table"
		size="small"
		stripedRows
		tableStyle="tw3-w-full"
		:value="fields"
	>
		<Column
			field="number"
			header="Phone number"
			:pt="{
				headerCell: {
					style: {
						width: '66.6%'
					}
				}
			}"
		>
			<template #body="{ index }">
				<InputMask
					v-model="fields[index].value.number"
					v-tooltip.top="{
						value: errors[`phones[${index}].number` as any],
						pt: {
							text: {
								style: { backgroundColor: 'rgb(248 113 113)' }
							},
							arrow: {
								style: { borderTopColor: 'rgb(248 113 113)' }
							}
						}
					}"
					class="tw3-w-full tw3-max-w-64"
					:invalid="!!errors[`phones[${index}].number` as any]"
					mask="(999) 999-9999"
					placeholder="Phone"
					type="text"
					@update:model-value="v => updateVal(index, 'number', v)"
				/>
			</template>
		</Column>

		<Column field="primary" header="Primary" style="width: 2.25rem">
			<template #body="{ index }">
				<div class="cell-container">
					<ToggleSwitch
						v-tooltip.top="'Set to primary'"
						:modelValue="store.primaryPhoneIndex === index"
						name="dynamic"
						:style="fields.length === 1 && 'opacity: 0.6'"
						@update:model-value="val => val && updatePrimary(index)"
					/>
				</div>
			</template>
		</Column>

		<Column field="primary" header="Secondary" style="width: 2.25rem">
			<template #body="{ index }">
				<div class="cell-container">
					<ToggleSwitch
						v-tooltip.top="'Set to secondary'"
						:modelValue="store.secondaryPhoneIndex === index"
						name="dynamic"
						:style="fields.length === 1 && 'opacity: 0.6'"
						@update:model-value="val => val && updateSecondary(index)"
					/>
				</div>
			</template>
		</Column>

		<Column field="actions" header="" style="width: 5%">
			<template #header>
				<div class="tw3-flex">
					<Button
						aria-label="Settings"
						icon="pi pi-plus"
						rounded
						size="small"
						text
						type="button"
						@click="add"
					/>
				</div>
			</template>

			<template #body="{ index }">
				<div class="tw3-flex tw3-flex-col tw3-items-center tw3-justify-center">
					<Button
						aria-label="Delete"
						class="tw3-w-8 tw3-h-8"
						icon="pi pi-trash"
						rounded
						severity="danger"
						text
						@click="removeVal(index)"
					/>
				</div>
			</template>
		</Column>
	</DataTable>
</template>

<style lang="scss" scoped>
.ui-data-table *:not(input) {
	border-width: 0px !important;
}
</style>
