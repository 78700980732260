import { defaultOptions } from '@primevue/core/config';
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import AnimateOnScroll from 'primevue/animateonscroll';
import BadgeDirective from 'primevue/badgedirective';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import FocusTrap from 'primevue/focustrap';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import singleSpaVue from 'single-spa-vue';
import { createApp, h } from 'vue';
import './assets/styles/index.css';

import { storeReset } from '@/store/plugins/storeReset';

import App from './App.vue';
import i18n from './i18n';
import router from './router';

const CustomPreset = definePreset(Aura, {
	components: {
		tag: {
			padding: `${0.25 * (14 / 16)}rem ${0.5 * (14 / 16)}rem`
		},
		dialog: { header: { padding: '0.75rem 1.25rem' } }
		// card: { body: { gap: '0px' } }
	}
});

const vueLifecycles = singleSpaVue({
	appOptions: {
		render() {
			return h(App, {});
		}
	},
	createApp,

	handleInstance(app) {
		const store = createPinia();
		store.use(piniaPluginPersistedstate);

		app.use(PrimeVue, {
			ripple: true,
			theme: {
				options: {
					cssLayer: false,
					darkModeSelector: 'none'
				},
				preset: CustomPreset
			},
			unstyled: false,

			zIndex: {
				menu: 11100, //overlay menus
				modal: 11000, //dialog, sidebar
				overlay: 10000, //dropdown, overlaypanel
				tooltip: 11101 //tooltip
			},
			locale: {
				...defaultOptions.locale,
				dateFormat: 'yy-mm-dd'
			}
		});

		app.use(i18n);
		app.use(ConfirmationService);
		app.use(ToastService);
		app.use(DialogService);
		store.use(storeReset);
		app.use(store);
		app.use(router);

		app.directive('badge', BadgeDirective);
		app.directive('tooltip', Tooltip);
		app.directive('ripple', Ripple);
		app.directive('styleclass', StyleClass);
		app.directive('focustrap', FocusTrap);
		app.directive('animateonscroll', AnimateOnScroll);
	}
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
