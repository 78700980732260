import { capitalize } from 'lodash-es';

const EN = {
	agencyName: 'Agency Name',
	agentFirstName: 'Agent First Name',
	agentLastName: 'Agent Last Name',
	all: 'All',
	amount: 'Amount',
	appointmentDate: 'Appointment Date',
	archive: 'Archive',
	archiveDialog: {
		archiveTitle: 'Are you sure you want to unarchive this Claim?',
		unarchiveTitle: 'Are you sure you want to archive this Claim?'
	},
	autoAccept: 'Auto Accept',
	bodyInjury: 'Bodily Injury',
	bodyInjuryTooltip: 'Was there an injury as it relates to this claim',
	//@ts-ignore
	capitalize: function (ctx) {
		//@ts-ignore
		return capitalize(ctx.named('key'));
	},
	causeOfLoss: 'Cause of Loss',
	causeOfLossTooltip: 'Determine how loss occurred',
	changeFollowupDate: {
		message: 'Are you sure you want to update this information?',
		title: 'Change Follow Up date'
	},
	city: 'City',
	claimForJob: 'Claim for Job',
	claims: 'Claims',
	contactPhone: 'Contact Phone',
	coverage: 'coverage',
	csr: 'CSR',
	customer: 'Customer',
	damage: 'Damage',
	damageTitles: {
		location: 'Capture what glass will be repaired or replaced',
		position: 'Nags Position Code',
		qty: 'Capture the pieces of glass\nthat will be used to service the vehicle',
		relLocation: 'Nags Relative Location Code',
		repairable:
			'If Glass Damage Opening is Windshield, \nthe Retailer must certify that the windshield\nwas not repairable',
		side: 'Nags Side Code'
	},
	date: 'Date',
	deductable: 'Deductable',
	details: 'Details',
	driverName: `Driver's Name (if available)`,
	error: 'error',
	errorNumber: 'Please enter a valid 10 digit number.',
	followUp: 'Follow up',
	glassOnlyLoss: 'Glass Only Loss',
	glassOnlyLossTooltip: 'Determine if this is a Glass Only claim',
	inbox: 'Inbox',
	incident: 'Incident on',
	insurance: 'Insurance',
	jobId: 'Job ID',
	location: 'Location',
	mobileIndicator: 'Mobile Indicator',
	new: 'new',
	no: 'No',
	noData: 'No Data Found',
	openClaim: 'Open Claim',
	pending: 'pending',
	phone: 'Phone',
	plate: 'Plate',
	policy: 'Policy',
	position: 'Position',
	qty: 'Qty',
	quote: 'Quote#',
	refNumber: 'Reference Number',
	reference: 'Reference',
	relLocation: 'Rel Location',
	removeDamageInfoHeader: 'Remove Damage Information',
	removeDamageInfoMsg:
		'Are you sure you want to delete this damage information?',
	repairable: 'Repairable',
	required: 'Required',
	result: 'Result',
	selectShop: 'Select Shop',
	shopContactPerson: 'Shop Contact Person',
	shopContactPersonTooltip:
		'Allows Insurance Company or TPA to contact the originator.',
	showActive: 'Show Active',
	showArchive: 'Show Archive',
	side: 'Side',
	statePolicty: 'State Policy was Written',
	status: 'Status',
	statusResponses: {
		coverage: 'coverage response received',
		error: 'error occured',
		new: 'claim created',
		pending: 'loss notice sent',
		success: 'acknowledge sent'
	},
	street: 'Street',
	submitClaim: 'Submit Claim',
	submitClaimMsg: 'Are you sure you would like to submit this claim?',
	subrogationContactName: {
		CAUSE_OF_LOSS_105: "Driver's Name (if available)",
		CAUSE_OF_LOSS_111: 'Details',
		CAUSE_OF_LOSS_199: "Person's Name (if available/applicable)",
		CAUSE_OF_LOSS_201: "Driver's Name (if available)",
		CAUSE_OF_LOSS_301: "Person's Name (if available)",
		CAUSE_OF_LOSS_341: "Person's Name (if available)",
		CAUSE_OF_LOSS_499: 'Details'
	},
	subrogationData: 'Subrogation Data',
	subrogationDataKeys: {
		CAUSE_OF_LOSS_105: 'License Plate Number',
		CAUSE_OF_LOSS_111: 'Type of Animal',
		CAUSE_OF_LOSS_199: 'Description of Object',
		CAUSE_OF_LOSS_201: 'Claim Number (if available) and Contact Info',
		CAUSE_OF_LOSS_301: 'Contact Info',
		CAUSE_OF_LOSS_341: 'Contact Info (e.g. policy report)',
		CAUSE_OF_LOSS_499: 'Type of Storm (e.g. lightning, wind, sand, etc)'
	},
	success: 'success',
	type: 'type',
	unarchive: 'Unarchive',
	vehicle: 'Vehicle',
	vehicleNumber: 'Vehicle Number',
	vehicleOwnership: 'Vehicle Ownership',
	vehicleType: 'Vehicle Type',
	vin: 'VIN',
	vinTooltip:
		'A policyholder often has more than one vehicle with the same make/model/yr on a policy, therefore, if available, the VIN may be sent to filter coverage confirmation information.',
	workLocation: 'Work Location Postal Code',
	yes: 'Yes'
};

export default EN;
