//insurance, additionalInfo, acknowledgementDetails, damageInfo

export const fieldsToCheck = [
	'insurerId',
	'originationContactName',
	'glassOnlyDamage',
	'workLocationPostalCode',
	'requestedAppointmentDt',
	'mobileIndicator',
	'openingTypeCode',
	'repairQualification',
	'glassDamageQuantity'
];
