<script setup lang="ts">
import Card from 'primevue/card';
import { computed, onMounted, ref } from 'vue';

import { getAddress } from '@/entities/customer/lib/api';
import { customersHeader } from '@/entities/customer/lib/contants';
import {
	useCustomerEditStore,
	useCustomersStore
} from '@/entities/customer/lib/store';
import { Address } from '@/features/address/lib/types';
import useStorageStore from '@/features/customer/lib/storageStore';
import UpdateColumns from '@/features/customer/UpdateColumns.vue';
import AddressDialog from '@/widgets/addressDialog/AddressDialog.vue';
import CustomersList from '@/widgets/customers/CustomersList.vue';
import EditCustomerDialog from '@/widgets/editCustomerDialog/editCustomerDialog.vue';

const store = useCustomersStore();
const editStore = useCustomerEditStore();
const storage = useStorageStore();

const address = ref<Address>();
const showAddress = ref(false);

const showColumnSettings = ref(false);
const showEditDialog = ref(false);

const selectedColumns = computed(() =>
	storage.customerColumns.length ? storage.customerColumns : customersHeader
);

const toggleAddress = async (id: number, addressId: number) => {
	if (address.value && address.value.id === addressId) return;
	address.value = await getAddress(id, addressId);
	showAddress.value = true;
};

const toggleEdit = async (id: string) => {
	await editStore.customer.execute(0, { id });
	showEditDialog.value = true;
};

onMounted(() => store.customers.execute(0, store.composeGetCustomersArgs(0)));
</script>

<template>
	<Card
		pt:body:class="tw3-overflow-auto"
		pt:content:class="tw3-overflow-auto"
		pt:root:class="tw3-grow tw3-overflow-auto"
	>
		<template #title>
			<div>My Customers</div>
		</template>
		<template #content>
			<EditCustomerDialog v-model:show="showEditDialog" />
			<UpdateColumns
				v-model:show="showColumnSettings"
				:modelValue="selectedColumns"
				@save="storage.updateCustomerColumns"
			/>
			<AddressDialog
				v-if="address"
				v-model:show="showAddress"
				:fullAddress="address.full_address"
				:lat="address.lat"
				:lng="address.lng"
			/>
			<CustomersList @on-edit="toggleEdit" @on-open-address="toggleAddress" />
		</template>
	</Card>
</template>
