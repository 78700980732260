import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';

import { getInsurances } from '@/api/insurances';
import { InsuranceRequest } from '@/api/insurances/types';

const useInsuranceStore = defineStore('insurances', () => {
	const insurances = useAsyncState(
		(args: InsuranceRequest) => {
			return getInsurances(args);
		},
		[],
		{
			immediate: false,
			shallow: false
		}
	);

	return { insurances };
});

export default useInsuranceStore;
