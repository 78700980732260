import { Address } from '@/features/address/lib/types';

export enum ComparisonType {
	like = 'like',
	exact = 'exact'
}

export type EdiPartners = {
	id: number;
	trading_partner: string;
	name: string;
	program_id: string;
};

export type CustomersTableHeader = {
	key: string;
	title: string;
};

export enum CustomersSortBy {
	id = 'id',
	notes = 'notes',
	company_name = 'company_name',
	fax = 'fax',
	full_address = 'full_address',
	name_first = 'name_first',
	name_last = 'name_last',
	email = 'email',
	phone = 'phone',
	status = 'status',
	flagged = 'flagged',
	tax_exempt = 'tax_exempt',
	po_required = 'po_required',
	customer_type = 'customer_type'
}

export enum CustomerStatus {
	all = 'all',
	active = 'active',
	inactive = 'inactive'
}

export enum CustomerType {
	Cash = 'Cash',
	Commercial = 'Commercial',
	Insurance = 'Insurance'
}

export type CustomersFiltersWithMetadata<T> = {
	[K in keyof T]: {
		val: string | string[] | null;
		type: string;
	};
};

export type Option = {
	id: number;
	full_name: string;
};

export type CustomerName = {
	id: number;
	first: string | null;
	last: string | null;
	middle: string | null;
};

type CustomerRatesKit = {
	id: number;
	type: string | null;
	amount: number | null;
};

type CustomerRatesRecall = {
	id: number;
	dynamic: number | null;
	static: number | null;
	dual: number | null;
};

export type CustomerRates = {
	id: number;
	chip_first: number;
	chip_additional: number;
	labor_flat: number;
	labor_hourly: number;
	name: string;
	labor_flat_tempered: number;
	labor_hourly_tempered: number;
	standard_kit: CustomerRatesKit;
	high_kit: CustomerRatesKit;
	fast_kit: CustomerRatesKit;
	safelite_recals: CustomerRatesRecall;
	lynx_rtl_recals: CustomerRatesRecall;
	lynx_dlr_recals: CustomerRatesRecall;
	discounts: {
		id: number;
		glass: number | null;
		moulding: number | null;
		oem: number | null;
		tempered: number | null;
	};
};

export type ShopSettings = {
	id: number;
	consumer_id: number;
	shop_id: number;
	pricelevel_id: number | null;
	rates: CustomerRates | null;
	override_allowed_shop: number;
};

export type CustomerTaxes = {
	default_tax: number;
	flat: number;
	for_labor: number;
	for_materials: number;
	id: number;
	mobile_only: number;
	name: string;
	percentage: number;
	status: string;
};

export type CustomerPhone = {
	id: number;
	number: string;
};

export type CustomerEmail = {
	id: number;
	email: string;
};

type CustomerSetting = {
	id: number;
	full_name?: string;
};

type CustomerInitialLocation = {
	id: number;
	address_id: number;
	name: string | null;
	shop_id: number | null;
	status: string;
	address: Address;
};

type CustomerEdi = {
	capable: number;
	trading_partner: string | null;
	partner_program_id: string | null;
	program_name: string | null;
};

export type CustomerVehicle = {
	consumer_id: number;
	description: string | null;
	id: number;
	image_url: string | null;
	make: string | null;
	make_id: number;
	model: string | null;
	model_id: number;
	nags_id: number;
	plate_number: string | null;
	state: string | null;
	style: string | null;
	style_id: number | null;
	unit_number: string | null;
	vin: string;
	vin_is_valid: number;
	year: number;
};

export type Customer = {
	id: number;
	company_name: string;
	is_commercial: number;
	is_insurance: number;
	tax_exempt: number | null;
	organization_id: number;
	notes: string | null;
	auto_popup: number | null;
	auto_popup_message: string | null;
	po_required: number | null;
	auto_add_adhesive: number | null;
	auto_add_to_fleet: number | null;
	auto_set_install_address: number | null;
	consumer_confirmation_settings: {
		send: number | null;
	} | null;
	flagged: number | null;
	resale_id: string | null;
	default_invoice_tier: string | null;
	fax?: string | null;
	website?: string | null;
	status: string | null;
	override_allowed_shops: number;
	standing_po: string | null;
	guest: Record<string, string> | null;
	name: CustomerName | null;
	phone: CustomerPhone | null;
	secondary_phone: CustomerPhone | null;
	email: CustomerEmail | null;
	address: Address | null;
	install_location: CustomerInitialLocation | null;
	vehicles: CustomerVehicle[];
	taxes: CustomerTaxes[];
	edi: CustomerEdi | null;
	bill_to: CustomerSetting | null;
	salesrep: CustomerSetting | null;
	tech: CustomerSetting | null;
	emails: CustomerEmail[];
	phones: CustomerPhone[];
	addresses: Address[];
	names: CustomerName[];
	consumer_shop_settings: ShopSettings[];
};

export type CustomerJobs = {
	id: number;
	install_date: string | null;
	invoice_date: string | null;
	status: string | null;
	total_balance_after_payments: number;
};

export type CustomerTable = Pick<
	Customer,
	| 'id'
	| 'notes'
	| 'company_name'
	| 'fax'
	| 'auto_add_adhesive'
	| 'auto_popup'
	| 'website'
	| 'auto_popup_message'
	| 'default_invoice_tier'
	| 'auto_add_to_fleet'
	| 'status'
	| 'flagged'
	| 'tax_exempt'
	| 'po_required'
> & {
	g_address_id: number | null;
	full_address: string | null;
	vehicle_id: number | null;
	customer_type: string;
	name_first: string | null;
	name_last: string | null;
	email: string | null;
	phone: string | null;
	secondary_phone: string | null;
};

export type CustomerRequestParams = {
	offset: number;
	getCount: boolean;
	sort: {
		sortBy: string;
		sortOrder: string;
	};
	filters?: any;
};

export type CustomerResponse<T> = {
	customers: T[];
	count?: number;
};
