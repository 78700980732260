<script setup lang="ts">
import Button from 'primevue/button';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Divider from 'primevue/divider';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Popover from 'primevue/popover';
import Select from 'primevue/select';
import Textarea from 'primevue/textarea';
import ToggleButton from 'primevue/togglebutton';
import { computed, onMounted, ref } from 'vue';

import { edis, invoiceTerms } from '@/constants/editCustomer';
import useCustomerEditStore from '@/store/customerEdit/customerEditStore';
import { Customer, EdiPartners, ShopSettings } from '@/types/customer';

import CustomerInputAddress from '../../CustomerInputAddress.vue';

import UpdateBillTo from './UpdateBillTo.vue';

const regex1 = /\b\d{4} \d{4} \d{4} \d{4}\b/;
const regex2 = /\b\d{16}\b/;

const store = useCustomerEditStore();
const isNotesWarning = computed(() =>
	store.customer.state.notes
		? regex1.test(store.customer.state.notes) ||
			regex2.test(store.customer.state.notes)
		: undefined
);
const isAutopopupWarning = computed(() =>
	store.customer.state.auto_popup_message
		? regex1.test(store.customer.state.auto_popup_message) ||
			regex2.test(store.customer.state.auto_popup_message)
		: undefined
);

const popWarning = ref<any>();
const popAutopopupWarning = ref<any>();

const showUpdateBill = ref(false);

const tradingPartner = computed(
	() => store.customer.state.edi?.trading_partner
);
const partnerProgramId = computed(
	() => store.customer.state.edi?.partner_program_id
);

const shopsToAppearIn = computed(() => {
	const shopsToAppearIn = [];
	for (let i = 0; i < store.customer.state.consumer_shop_settings.length; i++) {
		if (store.customer.state.consumer_shop_settings[i].override_allowed_shop) {
			shopsToAppearIn.push(
				store.customer.state.consumer_shop_settings[i].shop_id
			);
		}
	}
	return shopsToAppearIn;
});

const updateShopsToAppearIn = (shopsToAppearIn: number[]) => {
	const consumerSettings = [...store.customer.state.consumer_shop_settings];
	for (let i = 0; i < consumerSettings.length; i++) {
		consumerSettings[i].override_allowed_shop = 0;
	}

	for (let j = 0; j < shopsToAppearIn.length; j++) {
		let foundShopSetting = false;
		for (let i = 0; i < consumerSettings.length; i++) {
			if (consumerSettings[i].shop_id === shopsToAppearIn[j]) {
				consumerSettings[i].override_allowed_shop = 1;
				foundShopSetting = true;
			}
		}
		if (!foundShopSetting) {
			const newConsumerShopSetting = {
				override_allowed_shop: 1,
				pricelevel_id: null,
				rates: null,
				shop_id: shopsToAppearIn[j]
			};
			consumerSettings.push(newConsumerShopSetting as ShopSettings);
		}
	}

	updateValue('consumer_shop_settings', consumerSettings);
};

const toggleWarning = (event: MouseEvent, show: boolean) => {
	if (show) popWarning.value.show(event);
	else popWarning.value.hide(event);
};

const toggleAutopopupWarning = (event: MouseEvent, show: boolean) => {
	if (show) popAutopopupWarning.value.show(event);
	else popAutopopupWarning.value.hide(event);
};

const updateValue = (field: keyof Customer, value: any) => {
	store.customer.state = { ...store.customer.state, [field]: value };
};

const updateTradingPartner = async (v: string) => {
	if (v === 'none') {
		updateValue('edi', null);
	} else {
		await store.ediPartners.execute(0, { id: v });
		updateValue('edi', {
			capable: 1,
			partner_program_id: null,
			program_name: null,
			trading_partner: v
		});
	}
};

const updateEdiPartners = (value: string) => {
	const partner = store.ediPartners.state.find(
		(v: EdiPartners) => v.program_id === value
	) as unknown as EdiPartners;
	if (!partner) {
		updateValue('edi', {
			...store.customer.state.edi,
			partner_program_id: null,
			program_name: null
		});
	} else {
		updateValue('edi', {
			...store.customer.state.edi,
			partner_program_id: partner.program_id,
			program_name: partner.name
		});
	}
};

onMounted(() => {
	if (!store.salesrepOptions.state.length) {
		store.salesrepOptions.execute(0);
	}
	if (!store.techOptions.state.length) {
		store.techOptions.execute(0);
	}
	if (store.customer.state.edi && tradingPartner.value) {
		store.ediPartners.execute(0, { id: tradingPartner.value });
	}
	if (!store.shopsByOrganization.state.length) {
		store.shopsByOrganization.execute(0);
	}
});
</script>

<template>
	<div class="tw3-w-full tw3-flex tw3-flex-col tw3-gap-4">
		<Card pt:body:style="padding: 0" style="box-shadow: none">
			<template #title>General Settings</template>
			<template #content>
				<div class="tw3-flex tw3-gap-4 tw3-w-full">
					<div class="tw3-flex tw3-flex-col tw3-w-full tw3-bg-ye">
						<div
							class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
						>
							Notes
						</div>
						<Textarea
							class="tw3-w-full"
							field
							:modelValue="store.customer.state.notes"
							placeholder="Note"
							rows="3"
							:style="isNotesWarning ? 'border-color:  #fbbf24' : undefined"
							@mouseleave="v => toggleWarning(v, false)"
							@mouseover="v => toggleWarning(v, true)"
							@update:model-value="v => updateValue('notes', v)"
						/>
						<Popover
							v-if="isNotesWarning"
							ref="popWarning"
							class="tw3-max-w-96 customer-settings-notes-warning-popup"
						>
							<div class="tw3-flex tw3-items-center tw3-gap-4">
								<i class="pi pi-exclamation-triangle"></i>
								<div>
									Entering credit card info in the notes field is prohibited due
									to security risks.
								</div>
							</div>
						</Popover>
					</div>
					<div class="tw3-w-full">
						<div
							class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
						>
							Auto Popup Note
						</div>
						<InputGroup
							@mouseleave="v => toggleAutopopupWarning(v, false)"
							@mouseover="v => toggleAutopopupWarning(v, true)"
						>
							<InputGroupAddon style="padding: 0">
								<ToggleButton
									class="tw3-w-[71px] tw3-h-full"
									:modelValue="!!store.customer.state.auto_popup"
									offLabel="Enable"
									onLabel="Disable"
									@update:model-value="v => updateValue('auto_popup', +v)"
								/>
							</InputGroupAddon>
							<Textarea
								class="tw3-w-full"
								:disabled="!store.customer.state.auto_popup"
								field
								:modelValue="store.customer.state.auto_popup_message"
								placeholder="Auto Popup Note"
								rows="3"
								:style="
									isAutopopupWarning ? 'border-color:  #fbbf24' : undefined
								"
								@update:model-value="v => updateValue('auto_popup_message', v)"
							/>
							<Popover
								v-if="isAutopopupWarning"
								ref="popAutopopupWarning"
								class="tw3-max-w-96 customer-settings-notes-warning-popup"
							>
								<div class="tw3-flex tw3-items-center tw3-gap-4">
									<i class="pi pi-exclamation-triangle"></i>
									<div>
										Entering credit card info in the notes field is prohibited
										due to security risks.
									</div>
								</div>
							</Popover>
						</InputGroup>
					</div>
				</div>
			</template>
		</Card>
		<Divider style="margin: 0" />
		<Card pt:body:style="padding: 0" style="box-shadow: none">
			<template #title>Job Defaults</template>
			<template #content>
				<div class="tw3-m-0 tw3-flex tw3-flex-col tw3-gap-4">
					<div class="tw3-flex tw3-items-center tw3-gap-4">
						<div class="tw3-w-full tw3-max-w-64">
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Default Tech
							</div>
							<Select
								class="tw3-w-full"
								:loading="store.techOptions.isLoading"
								:modelValue="store.customer.state.tech?.id"
								optionLabel="name"
								:options="store.techOptions.state"
								optionValue="id"
								placeholder="Select item"
								@update:model-value="
									v => updateValue('tech', v == null ? v : { id: v })
								"
							/>
						</div>
						<div class="tw3-w-full tw3-max-w-64">
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Default Salesrep
							</div>
							<Select
								class="tw3-w-full"
								:loading="store.salesrepOptions.isLoading"
								:modelValue="store.customer.state.salesrep?.id"
								optionLabel="name"
								:options="store.salesrepOptions.state"
								optionValue="id"
								placeholder="Select item"
								@update:model-value="
									v => updateValue('salesrep', v == null ? v : { id: v })
								"
							/>
						</div>
					</div>
					<div
						v-if="!store.customer.state.is_insurance"
						class="tw3-w-full tw3-max-w-[462px]"
					>
						<div
							class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
						>
							Default Install Location
						</div>
						<CustomerInputAddress
							class="tw3-w-full"
							:modelValue="store.customer.state.install_location?.address"
							withExtra
							@update:model-value="
								v =>
									updateValue('install_location', {
										...store.customer.state.install_location,
										address: v.full_address ? v : null
									})
							"
						/>
					</div>
					<div class="tw3-flex tw3-gap-4">
						<div class="tw3-w-full tw3-max-w-64">
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Auto add adhesive
							</div>
							<ToggleButton
								class="p-togglebutton-boolean"
								:modelValue="!!store.customer.state.auto_add_adhesive"
								offLabel="No"
								onLabel="Yes"
								@update:model-value="v => updateValue('auto_add_adhesive', +v)"
							/>
						</div>
						<div
							v-if="!store.customer.state.is_insurance"
							class="tw3-w-full tw3-max-w-64"
						>
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Auto add vehicles to fleet
							</div>
							<ToggleButton
								class="p-togglebutton-boolean"
								:modelValue="!!store.customer.state.auto_add_to_fleet"
								offLabel="No"
								onLabel="Yes"
								@update:model-value="v => updateValue('auto_add_to_fleet', +v)"
							/>
						</div>
					</div>
					<div
						v-if="!store.customer.state.is_insurance"
						class="tw3-flex tw3-gap-4"
					>
						<div class="tw3-w-full tw3-max-w-64">
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Auto set install address
							</div>
							<ToggleButton
								class="p-togglebutton-boolean"
								:modelValue="!!store.customer.state.auto_set_install_address"
								offLabel="No"
								onLabel="Yes"
								@update:model-value="
									v => updateValue('auto_set_install_address', +v)
								"
							/>
						</div>
						<div class="tw3-w-full tw3-max-w-64">
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Auto send confirmation texts
							</div>
							<ToggleButton
								class="p-togglebutton-boolean"
								:modelValue="
									!!store.customer.state.consumer_confirmation_settings?.send
								"
								offLabel="No"
								onLabel="Yes"
								@update:model-value="
									v =>
										updateValue(
											'consumer_confirmation_settings',
											v ? { send: 1 } : { send: 0 }
										)
								"
							/>
						</div>
					</div>
				</div>
			</template>
		</Card>
		<Divider style="margin: 0" />
		<Card pt:body:style="padding: 0" style="box-shadow: none">
			<template #title>Billing Defaults</template>
			<template #content>
				<div class="tw3-m-0 tw3-flex tw3-flex-col tw3-gap-4">
					<div class="tw3-flex tw3-items-center tw3-gap-4">
						<div class="tw3-w-full tw3-max-w-64">
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Default Invoice Tier
							</div>
							<Select
								class="tw3-w-full"
								:modelValue="store.customer.state.default_invoice_tier"
								optionLabel="label"
								:options="invoiceTerms"
								optionValue="value"
								placeholder="Select item"
								showClear
								@update:model-value="
									v => updateValue('default_invoice_tier', v)
								"
							/>
						</div>
						<div class="tw3-w-full tw3-max-w-64">
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Standing PO
							</div>
							<InputText
								class="tw3-w-full"
								:modelValue="store.customer.state.standing_po"
								placeholder="Standing po"
								@update:model-value="v => updateValue('standing_po', v)"
							/>
						</div>
					</div>
					<div class="tw3-flex tw3-items-center tw3-gap-4">
						<div class="tw3-w-full tw3-max-w-64">
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Default Bill To
							</div>
							<Button
								class="tw3-w-full tw3-gap-1 tw3-leading-[21px]"
								iconPos="right"
								outlined
								severity="secondary"
								style="padding: 0.5rem 0.75rem"
								@click="showUpdateBill = true"
							>
								<div
									class="tw3-truncate tw3-w-full tw3-text-left tw3-text-[1rem]"
								>
									{{ store.customer.state.bill_to?.full_name ?? 'Select item' }}
								</div>
								<div
									v-if="store.customer.state.bill_to"
									@click.stop="() => updateValue('bill_to', null)"
								>
									<i class="pi pi-times"></i>
								</div>
							</Button>
							<UpdateBillTo
								v-model:show="showUpdateBill"
								@update-bill="v => updateValue('bill_to', v)"
							/>
						</div>
						<div class="tw3-w-full tw3-max-w-64">
							<div
								class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
							>
								Customer Resale ID
							</div>
							<InputText
								class="tw3-w-full"
								:modelValue="store.customer.state.resale_id"
								placeholder="Customer Resale ID"
								@update:model-value="v => updateValue('resale_id', v)"
							/>
						</div>
					</div>
				</div>
			</template>
		</Card>
		<Divider v-if="!!store.customer.state.is_insurance" style="margin: 0" />
		<Card
			v-if="!!store.customer.state.is_insurance"
			pt:body:style="padding: 0"
			style="box-shadow: none"
		>
			<template #title>EDI</template>
			<template #content>
				<div class="tw3-m-0 tw3-flex tw3-items-center tw3-gap-4">
					<div class="tw3-w-full tw3-max-w-64">
						<div
							class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
						>
							Trading Partner
						</div>
						<div class="tw3-w-full">
							<Select
								class="tw3-w-full"
								field
								:modelValue="tradingPartner"
								optionLabel="name"
								:options="edis"
								optionValue="id"
								placeholder="Select item"
								@update:model-value="updateTradingPartner"
							/>
						</div>
					</div>
					<div class="tw3-w-full tw3-max-w-64">
						<div
							class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
						>
							Trading Partner Program ID
						</div>
						<Select
							class="tw3-w-full"
							:disabled="
								!store.customer.state.edi || !store.ediPartners.state.length
							"
							filter
							:filterFields="['program_id', 'name']"
							:loading="store.ediPartners.isLoading"
							:modelValue="partnerProgramId"
							optionLabel="name"
							:options="store.ediPartners.state"
							optionValue="program_id"
							placeholder="Select item"
							showClear
							@update:model-value="updateEdiPartners"
						>
							<template #option="{ option }">
								<div class="tw3-flex tw3-items-end tw3-gap-2">
									<div>{{ option.name }}</div>
									<div class="tw3-text-xs tw3-text-inherit">
										ID: {{ option.program_id }}
									</div>
								</div>
							</template>
						</Select>
					</div>
				</div>
			</template>
		</Card>
		<Divider style="margin: 0" />
		<Card pt:body:style="padding: 0" style="box-shadow: none">
			<template #title>Shop Settings</template>
			<template #content>
				<div class="tw3-w-full">
					<div
						class="tw3-h-8 tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
					>
						Override which shops included on
					</div>
					<InputGroup>
						<InputGroupAddon>
							<Checkbox
								binary
								:modelValue="!!store.customer.state.override_allowed_shops"
								@update:model-value="
									v => updateValue('override_allowed_shops', +v)
								"
							/>
						</InputGroupAddon>
						<MultiSelect
							class="tw3-w-full tw3-max-w-64 tw3-items-center"
							:disabled="!store.customer.state.override_allowed_shops"
							display="chip"
							:loading="store.shopsByOrganization.isLoading"
							:modelValue="shopsToAppearIn"
							optionLabel="name"
							:options="store.shopsByOrganization.state"
							optionValue="id"
							placeholder="Select item"
							:showToggleAll="false"
							@update:model-value="updateShopsToAppearIn"
						/>
					</InputGroup>
				</div>
			</template>
		</Card>
	</div>
</template>

<style>
.customer-settings-notes-warning-popup {
	background-color: #fbbf24 !important;
}

.customer-settings-notes-warning-popup::after {
	border-bottom-color: #fbbf24 !important;
}
</style>
