import dayjs from 'dayjs';

import { ClaimStatus } from './types';

import type { NormalizedClaim } from './types';
import type { Claim } from '@/api/claims/types';

export const normazileInsuranceClaims = (data: Claim[]): NormalizedClaim[] => {
	return data.map(
		({
			archived,
			id,
			jobId,
			submittedDt,
			customerName,
			referenceNumber,
			vehicle,
			status
		}: Claim) => {
			const normalizeDate = dayjs(submittedDt).format('YYYY-MM-DD');

			return {
				archived,
				customerName,
				id,
				jobId,
				referenceNumber,
				status: ClaimStatus[status],
				submittedDt: normalizeDate,
				vehicle
			};
		}
	);
};
