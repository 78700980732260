import { FilterMatchMode } from '@primevue/core/api';

import { ClaimStatus } from '@/api/claims/types';
import i18n from '@/i18n';

import type {
	ClaimsFilters,
	ClaimsFiltersWithMetadata,
	ClaimsTableHeader,
	ClaimsWorkAssignmentsFilters
} from '@/types/claims';

export const chips = [{ key: 'claims', text: i18n.global.t('claims') }];

const claimsHeader: ClaimsTableHeader[] = [
	{ key: 'jobId', title: i18n.global.t('jobId') },
	{ key: 'submittedDt', title: i18n.global.t('date') },
	{ key: 'customerName', title: i18n.global.t('customer') },
	{ key: 'vehicle', title: i18n.global.t('vehicle') },
	{ key: 'referenceNumber', title: i18n.global.t('reference') },
	{ key: 'status', title: i18n.global.t('status') }
];
// const workAssignmentsHeader: InsuranceTableHeader[] = [{ key: 'id', title: 'ID', }, { key: 'referralNumber', title: 'Referral' }, { key: 'lastName', title: 'Last Name' }, { key: 'firstName', title: 'First Name', },  { key: 'phone', title: 'Phone' }, { key: 'jobNumber', title: 'Job' }, { key: 'yearMakeModel', title: 'Year Make Model' }, { key: 'scheduledDate', title: 'Scheduled Date' }, { key: 'actions', title: 'Actions', sortable: false }];

export const tableHeaders: ClaimsTableHeader[][] = [claimsHeader];

export const claimsFilters: ClaimsFiltersWithMetadata<ClaimsFilters> = {
	archived: { matchMode: FilterMatchMode.EQUALS, value: false },
	customerName: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	id: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	jobId: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	referenceNumber: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	status: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	submittedDt: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	vehicle: { matchMode: FilterMatchMode.CONTAINS, value: '' }
};

const workAssignmentsFilters: ClaimsWorkAssignmentsFilters = {
	customerName: '',
	id: '',
	jobNumber: '',
	phone: '',
	referralNumber: '',
	scheduledDate: '',
	yearMakeModel: ''
};

export const glaxisFilters = [claimsFilters, workAssignmentsFilters];

export const claimsParams = {
	archived: false,
	dateSubmitted: '',
	firstName: '',
	internalId: '',
	jobId: '',
	lastName: '',
	limit: 10,
	order: true,
	page: 1,
	referenceNumber: '',
	response: '',
	sort: 'internalId',
	status: ''
};

export const workAssignmentsParams = {
	archived: false,
	dateSubmitted: '',
	firstName: '',
	id: '',
	jobNumber: '',
	lastName: '',
	limit: 10,
	order: true,
	page: 1,
	phone: '',
	referralNumber: '',
	scheduledDate: '',
	sort: 'id',
	yearMakeModel: ''
};

export const ClaimStatuses: { [key in ClaimStatus]: string } = {
	CLAIM_STATUS_COVERAGE: i18n.global.t('coverage'),
	CLAIM_STATUS_ERROR: i18n.global.t('error'),
	CLAIM_STATUS_NEW: i18n.global.t('new'),
	CLAIM_STATUS_PENDING: i18n.global.t('pending'),
	CLAIM_STATUS_SUCCESS: i18n.global.t('success')
};

export const ClaimStatusesFullInfo: { [key in ClaimStatus]: string } = {
	CLAIM_STATUS_COVERAGE: i18n.global.t('statusResponses.coverage'),
	CLAIM_STATUS_ERROR: i18n.global.t('statusResponses.error'),
	CLAIM_STATUS_NEW: i18n.global.t('statusResponses.new'),
	CLAIM_STATUS_PENDING: i18n.global.t('statusResponses.pending'),
	CLAIM_STATUS_SUCCESS: i18n.global.t('statusResponses.success')
};

export const claimStatusOptions = [
	{
		label: i18n.global.t('capitalize', { key: 'coverage' }),
		value: 'CLAIM_STATUS_COVERAGE'
	},
	{
		label: i18n.global.t('capitalize', { key: 'new' }),
		value: 'CLAIM_STATUS_NEW'
	},
	{
		label: i18n.global.t('capitalize', { key: 'error' }),
		value: 'CLAIM_STATUS_ERROR'
	},
	{
		label: i18n.global.t('capitalize', { key: 'pending' }),
		value: 'CLAIM_STATUS_PENDING'
	},
	{
		label: i18n.global.t('capitalize', { key: 'success' }),
		value: 'CLAIM_STATUS_SUCCESS'
	}
];

export const archiveOptions = [
	{ label: i18n.global.t('showActive'), value: false },
	{ label: i18n.global.t('showArchive'), value: true },
	{ label: i18n.global.t('all'), value: 'all' }
];
