import { i18n } from '@/app';

export const calculateSubrogationData = (code: string) => {
	if (code === 'CAUSE_OF_LOSS_105') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_105'
			),
			SubrogationData: i18n.global.t('subrogationDataKeys.CAUSE_OF_LOSS_105')
		};
	} else if (code === 'CAUSE_OF_LOSS_111') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_111'
			),
			SubrogationData: i18n.global.t('subrogationDataKeys.CAUSE_OF_LOSS_111')
		};
	} else if (code === 'CAUSE_OF_LOSS_199') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_199'
			),
			SubrogationData: i18n.global.t('subrogationDataKeys.CAUSE_OF_LOSS_199')
		};
	} else if (code === 'CAUSE_OF_LOSS_201') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_201'
			),
			SubrogationData: i18n.global.t('subrogationDataKeys.CAUSE_OF_LOSS_201')
		};
	} else if (code === 'CAUSE_OF_LOSS_301') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_301'
			),
			SubrogationData: i18n.global.t('subrogationDataKeys.CAUSE_OF_LOSS_301')
		};
	} else if (code === 'CAUSE_OF_LOSS_341') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_341'
			),
			SubrogationData: i18n.global.t('subrogationDataKeys.CAUSE_OF_LOSS_341')
		};
	} else if (code === 'CAUSE_OF_LOSS_499') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_499'
			),
			SubrogationData: i18n.global.t('subrogationDataKeys.CAUSE_OF_LOSS_499')
		};
	}
	return {
		SubrogationContactName: null,
		SubrogationData: null
	};
};
