<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import ToggleSwitch from 'primevue/toggleswitch';
import { useFieldArray, useForm } from 'vee-validate';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useCustomerEditStore } from '@/entities/customer/lib/store';
import InputAddress from '@/features/address/InputAddress.vue';
import { Address } from '@/features/address/lib/types';

const ZOD_ADDRESS = 'address';

const props = defineProps<{
	modelValue: Address[];
}>();

const emit = defineEmits<{
	(e: 'update:modelValue', c: Address[]): void;
}>();

const { t } = useI18n();

const store = useCustomerEditStore();

const validationSchema = toTypedSchema(
	zod.object({
		[ZOD_ADDRESS]: zod.array(
			zod.object({
				address: zod.string(),
				city: zod.string().nullish(),
				extra: zod.string().nullish(),
				full_address: zod.string({ message: t('required') }),
				google_place_id: zod.string(),
				lat: zod.number(),
				lng: zod.number(),
				po_box: zod.number().nullish(),
				state: zod.string().nullish(),
				unit: zod.string().nullish(),
				zip: zod.string().nullish()
			})
		)
	})
);

const { errors, setValues } = useForm({
	initialValues: { [ZOD_ADDRESS]: props.modelValue },
	validationSchema
});

const { fields } = useFieldArray<Omit<Address, 'id'>>(ZOD_ADDRESS);

const add = () => {
	const val = {
		address: null,
		city: null,
		extra: null,
		full_address: null,
		google_place_id: null,
		lat: 0,
		lng: 0,
		po_box: null,
		state: null,
		unit: null,
		zip: null
	};
	if (!props.modelValue.length) store.primaryAddressIndex = 0;
	emit('update:modelValue', [...props.modelValue, val as any]);
};

const updateVal = (index: number, val: Partial<Address>) => {
	emit(
		'update:modelValue',
		props.modelValue.map((v, vIndex) => {
			if (vIndex === index) return val as Address;
			return v;
		})
	);
};

const removeVal = (index: number) => {
	emit(
		'update:modelValue',
		props.modelValue.filter((_, i) => i !== index)
	);
	if (store.primaryAddressIndex === index) {
		store.primaryAddressIndex = 0;
	} else if (store.primaryAddressIndex > index) {
		store.primaryAddressIndex -= 1;
	}
};

watch(
	() => props.modelValue,
	() => {
		setValues({ [ZOD_ADDRESS]: props.modelValue });
	}
);

watch(
	() => errors.value,
	() => store.updateErrors('addresses', errors.value)
);
</script>

<template>
	<DataTable
		class="ui-data-table"
		size="small"
		stripedRows
		tableStyle="tw3-w-full"
		:value="fields"
	>
		<Column
			field="address"
			header="Address"
			:pt="{
				headerCell: {
					style: {
						width: '66.6%'
					}
				}
			}"
		>
			<template #body="{ index }">
				<InputAddress
					class="tw3-w-full"
					:errMsg="errors[`address[${index}].full_address` as any]"
					:modelValue="fields[index].value"
					withExtra
					withPoBox
					@update:model-value="v => updateVal(index, v)"
				/>
			</template>
		</Column>

		<Column field="primary" header="Primary" style="display: flex">
			<template #body="{ index }">
				<div class="tw3-w-[2.25rem] tw3-mt-[0.375rem]">
					<ToggleSwitch
						v-tooltip.top="'Set to primary'"
						:modelValue="store.primaryAddressIndex === index"
						name="dynamic"
						:style="fields.length === 1 && 'opacity: 0.6'"
						@update:model-value="
							val =>
								val &&
								index !== store.primaryAddressIndex &&
								(store.primaryAddressIndex = index)
						"
					/>
				</div>
			</template>
		</Column>

		<Column field="actions" header="" style="width: 5%">
			<template #header>
				<div class="tw3-flex">
					<Button
						aria-label="Settings"
						icon="pi pi-plus"
						rounded
						size="small"
						text
						type="button"
						@click="add"
					/>
				</div>
			</template>

			<template #body="{ index }">
				<div class="tw3-flex tw3-flex-col tw3-items-center tw3-justify-center">
					<Button
						aria-label="Delete"
						class="tw3-w-8 tw3-h-8"
						icon="pi pi-trash"
						rounded
						severity="danger"
						text
						@click="removeVal(index)"
					/>
				</div>
			</template>
		</Column>
	</DataTable>
</template>

<style lang="scss" scoped>
.ui-data-table *:not(input):not(textarea):not(.p-checkbox-box) {
	border-width: 0px !important;
}
</style>
