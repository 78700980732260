<script setup lang="ts">
import Dialog from 'primevue/dialog';
import { GoogleMap, Marker } from 'vue3-google-map';

import { googleMapsConfig } from '@/constants/google-maps.config';

defineProps<{
	lat: number;
	lng: number;
	fullAddress: string;
}>();
const showModal = defineModel<boolean>('show');
</script>

<template>
	<Dialog
		id="vue3GoogleMapDialog"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-max-w-[750px]"
		:header="fullAddress"
		modal
	>
		<GoogleMap
			:apiKey="googleMapsConfig.apiKey"
			:center="{ lat, lng }"
			mapId="DEMO_MAP_ID"
			style="width: 100%; height: 400px"
			:zoom="15"
		>
			<Marker :options="{ position: { lat, lng }, title: fullAddress }" />
		</GoogleMap>
	</Dialog>
</template>
