import { defineStore } from 'pinia';
import { ref } from 'vue';

import { CustomersTableHeader } from './../../types/customer/index';

export const useStorageStore = defineStore(
	'storage',
	() => {
		const customerColumns = ref<CustomersTableHeader[]>([]);

		const updateCustomerColumns = (columns: CustomersTableHeader[]) => {
			customerColumns.value = columns;
		};

		return { customerColumns, updateCustomerColumns };
	},
	{ persist: true }
);

export default useStorageStore;
