<script setup lang="ts">
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Skeleton from 'primevue/skeleton';
import { onMounted, ref } from 'vue';

import { previousJobsHeader } from '@/constants/editCustomer';
import useCustomerEditStore from '@/store/customerEdit/customerEditStore';

const store = useCustomerEditStore();
const baseUrl = ref('');

const formatPrice = (i: number) => {
	return '$' + Math.abs(i).toFixed(2);
};

onMounted(() => {
	baseUrl.value = window.location.origin;
	store.previousJobs.execute(0);
});
</script>

<template>
	<DataTable
		class="tw3-w-full"
		:value="
			store.previousJobs.isLoading ? new Array(4) : store.previousJobs.state
		"
	>
		<template #empty>
			<div class="tw3-text-center">No Data</div>
		</template>
		<Column
			v-for="column of previousJobsHeader"
			:key="column.key"
			:field="column.key"
			:header="column.title"
			style="width: 20%"
		>
			<template #body="{ data }">
				<Skeleton v-if="store.previousJobs.isLoading" />
				<a
					v-else-if="column.key === 'id'"
					:href="baseUrl + '/jobs/' + data[column.key]"
					target="_blank"
				>
					{{ data[column.key] }}
				</a>
				<div
					v-else
					:class="{
						'tw3-text-right': column.key === 'total_balance_after_payments'
					}"
				>
					{{
						column.key === 'total_balance_after_payments'
							? formatPrice(data[column.key] ? data[column.key] : 0)
							: data[column.key]
					}}
				</div>
			</template>
		</Column>
	</DataTable>
</template>
