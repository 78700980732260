import {
	CauseOfLossCode,
	ClaimStatus,
	DamageInfo,
	VehicleOwnership,
	VehicleType
} from '@/api/claims/types';

const defaultAcknowledgementDetails = {
	acceptanceContact: '',
	mobileIndicator: false,
	requestedAppointmentDt: '',
	workLocationPostalCode: ''
};

const defaultAdditionalInfo = {
	bodilyInjury: false,
	causeOfLossCode: CauseOfLossCode.CAUSE_OF_LOSS_101,
	destinationPid: 1000, // Lynx services
	glassOnlyDamage: true,
	originationContactName: '',
	subrogationContactName: '',
	subrogationData: ''
};

const defaultAgent = {
	agencyName: '',
	firstName: '',
	lastName: '',
	phone: ''
};

const defaultClaimResult = {
	acknowledgementDt: '',
	acknowledgementRequest: '',
	acknowledgementResponse: '',
	claimRequest: '',
	claimResponse: '',
	coverageResponse: '',
	coverageResponseDt: '',
	coverageResponseVehicle: {
		alternativeNumber: '',
		deductible: 0,
		description: '',
		errorCode: '',
		errorText: '',
		nagsId: '',
		number: 0
	},
	errorCode: null,
	errorMessage: '',
	insurerPhone: '',
	referenceNumber: '',
	status: ClaimStatus.CLAIM_STATUS_NEW
};

const defaultCustomer = {
	city: '',
	firstName: '',
	id: 0,
	lastName: '',
	phone1: '',
	phone2: '',
	postalCode: '',
	state: '',
	streetAddress: ''
};

const defaultInsurance = {
	dateOfLoss: '',
	id: 0,
	insurerId: '',
	policyNumber: '',
	policyState: ''
};

const defaultDamageInfo: DamageInfo[] = [];

const defaultVehicle = {
	id: 0,
	imageUrl: '',
	make: '',
	makeId: 0,
	model: '',
	nagsId: '',
	number: 0,
	ownership: VehicleOwnership.VEHICLE_OWNERSHIP_OWNER,
	plateNumber: '',
	style: '',
	thumbUrl: '',
	type: VehicleType.VEHICLE_TYPE_STANDARD,
	vin: '',
	year: 0
};

export const defaultClaimDetailsState = {
	acknowledgementDetails: defaultAcknowledgementDetails,
	additionalInfo: defaultAdditionalInfo,
	agent: defaultAgent,
	archived: false,
	createdDt: '',
	createdUserId: 0,
	customer: defaultCustomer,
	damageInfo: defaultDamageInfo,
	id: 0,
	insurance: defaultInsurance,
	jobId: 0,
	providerName: '',
	result: defaultClaimResult,
	shopId: 4,
	uniqueId: '',
	vehicle: defaultVehicle
};
