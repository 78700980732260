import { FilterMatchMode } from '@primevue/core/api';

import i18n from '@/i18n';
import { QuotesFiltersWithMetadata, QuotesTableHeader } from '@/types/quotes';

export const quotesHeader: QuotesTableHeader[] = [
	{ key: 'id', title: i18n.global.t('quote') },
	{ key: 'customerName', title: i18n.global.t('customer') },
	{ key: 'customerPhone', title: i18n.global.t('phone') },
	{ key: 'vehicle', title: i18n.global.t('vehicle') },
	{ key: 'gbCsrId', title: i18n.global.t('csr') },
	{ key: 'followupDate', title: i18n.global.t('followUp') },
	{ key: 'quoteDate', title: i18n.global.t('date') },
	{ key: 'amount', title: i18n.global.t('amount') }
];

export type NormalizedQuote = {
	id: number;
	customerName: string;
	customerPhone: string;
	vehicle: string;
	followupDate: string | null;
	quoteDate: string;
	amount: string;
	gbCsrId: string | null;
};

export const quotesFilters: QuotesFiltersWithMetadata<NormalizedQuote> = {
	amount: { matchMode: FilterMatchMode.CONTAINS, value: null },
	customerName: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	customerPhone: { matchMode: FilterMatchMode.CONTAINS, value: null },
	followupDate: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	gbCsrId: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	id: { matchMode: FilterMatchMode.CONTAINS, value: null },
	quoteDate: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	vehicle: { matchMode: FilterMatchMode.CONTAINS, value: '' }
};
