import {
	Customer,
	CustomerAddress,
	CustomerJobs,
	EdiPartners,
	Option
} from '@/types/customer';

import CORE_API from '../core';

const CUSTOMER_URL = '/unum/consumer';
const EDI_PARTNERS_URL = '/unum/job-details/edi/partners';

export const getCustomer = async (id: number): Promise<Customer> => {
	try {
		const response = await CORE_API.get<Customer>(`${CUSTOMER_URL}/list/${id}`);

		return {
			...response.data,
			emails: response.data.emails.filter(v => !!v.email),
			phones: response.data.phones.filter(v => !!v.number),
			addresses: response.data.addresses.filter(v => !!v.full_address),
			names: response.data.names.filter(v => !!v.first)
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getCustomerAddress = async (
	id: number,
	addressId: number
): Promise<CustomerAddress> => {
	try {
		const response = await CORE_API.get<CustomerAddress>(
			`${CUSTOMER_URL}/list/${id}/detail/g_address/${addressId}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getCustomerPreviousJobs = async (
	id: number
): Promise<CustomerJobs[]> => {
	try {
		const response = await CORE_API.get<CustomerJobs[]>(
			`${CUSTOMER_URL}/job/${id}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getEdiPartners = async (v: string): Promise<EdiPartners[]> => {
	try {
		const response = await CORE_API.get<EdiPartners[]>(
			`${EDI_PARTNERS_URL}/${v}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getBillTo = async (
	organization_id: number,
	offset: number,
	term?: string
): Promise<{
	rows: (Option & { trading_partner: string })[];
	count: number;
}> => {
	try {
		const response = await CORE_API.get<{
			rows: (Option & { trading_partner: string })[];
			count: number;
		}>(
			`${CUSTOMER_URL}/search/q/?term=${term}&type=insurance&organization_id=${organization_id}&offset=${offset}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
